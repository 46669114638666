import React from "react";

function GetExpert() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <rect width="60" height="60" fill="#FEF7ED" rx="13"></rect>
            <path
                fill="#000"
                d="M16.77 40.338l6.761-4.38h8.178c.288 0 .563.113.766.315.203.201.317.474.317.76v1.142h-6.19a.576.576 0 00-.405.167.567.567 0 00.405.972h6.685a.6.6 0 00.047-.003.576.576 0 00.407-.136l7.043-6.022a1.085 1.085 0 011.703.392c.058.137.087.284.085.432a1.055 1.055 0 01-.358.786l-8.032 7.181c-.305.273-.701.424-1.112.424h-8.3a.58.58 0 00-.27.066l-4.51 2.376a.57.57 0 00-.237.77.572.572 0 00.508.302.575.575 0 00.268-.066l4.383-2.31h8.159c.694 0 1.364-.255 1.88-.716l8.032-7.182a2.22 2.22 0 00.737-1.62 2.203 2.203 0 00-.701-1.634 2.234 2.234 0 00-2.984-.064L33.94 37.5v-.47c0-.586-.236-1.148-.654-1.563a2.242 2.242 0 00-1.576-.648H23.36a.576.576 0 00-.314.092l-6.905 4.473a.57.57 0 00-.166.788.574.574 0 00.794.165zM30.704 16.138c2.596 0 4.709 2.095 4.709 4.671a4.622 4.622 0 01-.805 2.615c-.237.374-1.586 2.565-1.879 4.192h-4.05c-.293-1.628-1.645-3.82-1.88-4.192a4.622 4.622 0 01-.805-2.615c0-2.576 2.113-4.67 4.71-4.67zm-4.891 7.867c.493.776 1.774 3.04 1.774 4.18a.564.564 0 00.354.526c.07.028.144.043.22.043h5.087a.576.576 0 00.574-.57c0-1.136 1.28-3.402 1.773-4.18a5.75 5.75 0 00.965-3.195c0-3.203-2.627-5.809-5.856-5.809-3.23 0-5.856 2.606-5.856 5.809a5.75 5.75 0 00.956 3.181l.01.015h-.001zM33.798 30.14a.567.567 0 00-.168-.402.577.577 0 00-.405-.167h-4.95a.577.577 0 00-.407.167.567.567 0 00.406.972h4.95a.578.578 0 00.53-.352.564.564 0 00.044-.218zM29.423 31.433a.576.576 0 00-.406.167.567.567 0 00.406.972h2.654a.578.578 0 00.53-.352.566.566 0 00-.31-.743.578.578 0 00-.22-.044h-2.654z"
            ></path>
            <path
                fill="#000"
                d="M29.628 23.79a.575.575 0 00.811 0l3.502-3.474a.567.567 0 00-.406-.972.576.576 0 00-.405.167l-3.096 3.07-1.28-1.269a.577.577 0 00-.812 0 .567.567 0 000 .805l1.685 1.671v.001z"
            ></path>
        </svg>
    );
}

export default GetExpert;
