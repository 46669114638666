import React, { ReactElement } from "react";
import MainImg from "./icons/mainImg";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
export default function WhatIsDebtRelief(): ReactElement {
    return (
        <section
            className={`flex flex-col lg:flex-row  lg:gap-20 gap-8 lg:mt-56 mt-24  ${styles["beforeStyle"]}`}
        >
            <div className="max-w-[530px] mx-auto lg:mx-0 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#0071AC] ">
                    What Is Debt Relief?
                </h2>

                <p className="mt-10  lg:text-lg  text-base lg:leading-8 ">
                    Debt relief is all about finding strategies and programs to
                    help individuals or businesses manage and reduce their
                    debts. It's about getting some financial relief and easing
                    the weight of overwhelming debt. You have options like debt
                    consolidation, settlement, or management plans.
                </p>
                <p className="mt-5  lg:text-lg  text-base lg:leading-8 ">
                    In serious cases, bankruptcy might even be on the table. Not
                    to worry, though! Reputable debt relief companies and credit
                    counseling agencies offer these programs. The aim is to
                    negotiate with creditors, get lower interest rates, reduce
                    overall debt, or create doable repayment plans. It's all
                    about taking control of your finances and working towards a
                    debt-free future.
                </p>
                <div className="mt-12 hidden sm:block">
                    <SharedButton />
                </div>
            </div>
            <div className="w-[242px] h-[160px] md:w-[573px] md:min-w-[573px] md:h-[379px]  relative  mx-auto lg:mx-0 lg:mt-28 ">
                <MainImg />
            </div>
            <div className="mt-12 text-center sm:hidden">
                <SharedButton />
            </div>
        </section>
    );
}
