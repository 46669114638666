import React from "react";

function MainImg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 574 379"
        >
            <path
                fill="#F0F0F0"
                d="M282.5 362c156.02 0 282.5-9.85 282.5-22s-126.48-22-282.5-22C126.48 318 0 327.85 0 340s126.48 22 282.5 22z"
            ></path>
            <path
                fill="#F7941D"
                d="M224.127 338.626l-79.432 26.061-8.087 11.175 13.472 3.133 76.295-34.398-2.248-5.971z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M136.608 331.037c-4.896 0-8.866 3.953-8.866 8.827 0 4.875 3.97 8.83 8.866 8.83 4.896 0 8.866-3.958 8.866-8.83s-3.97-8.827-8.866-8.827zM206.854 330.183c-5.369 0-9.724 4.331-9.724 9.681 0 5.351 4.355 9.685 9.724 9.685s9.724-4.339 9.724-9.685c0-5.345-4.355-9.681-9.724-9.681z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M136.608 332.242v15.253l70.246 2.043v-19.35l-70.246 2.054z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M57.08 328.129c-2.344-1.219-4.928-.892-5.778.724-.847 1.613.366 3.909 2.71 5.13 2.344 1.216 4.932.898 5.782-.718.844-1.619-.366-3.912-2.71-5.136h-.003z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M51.742 331.273c-5.893-1.496-10.89-1.697-11.18-.454-.284 1.243 4.262 3.466 10.153 4.962 5.887 1.493 10.898 1.703 11.182.457.279-1.243-4.262-3.466-10.155-4.965z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M48.023 335.77c-6.07-.348-11.013.408-11.06 1.684-.04 1.273 4.85 2.587 10.918 2.938 6.069.345 11.025-.405 11.063-1.681.035-1.276-4.85-2.593-10.92-2.941z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M50.376 340.754c-6.046.647-10.8 2.198-10.634 3.465.17 1.26 5.21 1.76 11.254 1.116 6.04-.648 10.808-2.196 10.636-3.46-.174-1.265-5.21-1.766-11.259-1.121h.003zM53.485 345.076c-6.046.645-10.825 1.935-10.694 2.889.134.947 5.15 1.195 11.194.555 6.044-.642 10.836-1.934 10.7-2.886-.143-.947-5.151-1.2-11.2-.558z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M57.105 329.68c-4.62 0-8.366 3.726-8.366 8.329v3.898c0 4.603 3.746 8.33 8.366 8.33 4.623 0 8.363-3.727 8.363-8.33v-3.898c0-4.603-3.743-8.329-8.363-8.329z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M136.608 332.242H68.241v15.244h68.367v-15.244z"
            ></path>
            <path
                fill="#fff"
                d="M68.241 332.242h-4.554v15.244h4.554v-15.244z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M123.551 309.639c-4.896 0-8.866 3.953-8.866 8.828 0 4.874 3.97 8.827 8.866 8.827 4.896 0 8.866-3.955 8.866-8.827 0-4.873-3.97-8.828-8.866-8.828zM193.797 308.788c-5.371 0-9.724 4.33-9.724 9.681 0 5.351 4.355 9.682 9.724 9.682s9.724-4.339 9.724-9.682-4.355-9.681-9.724-9.681z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M123.551 310.844v15.253l70.246 2.043v-19.35l-70.246 2.054z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M44.024 306.734c-2.345-1.219-4.93-.895-5.779.721-.847 1.613.366 3.912 2.71 5.13 2.345 1.216 4.932.898 5.782-.718.844-1.616-.366-3.912-2.71-5.133h-.003z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M38.685 309.879c-5.89-1.496-10.89-1.698-11.18-.455-.284 1.241 4.265 3.463 10.153 4.962 5.887 1.494 10.898 1.7 11.182.457.28-1.246-4.262-3.465-10.155-4.964z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M34.967 314.373c-6.071-.346-11.016.408-11.06 1.686-.041 1.271 4.85 2.587 10.92 2.935 6.068.346 11.025-.405 11.063-1.681.035-1.273-4.85-2.592-10.92-2.94h-.003z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M37.319 319.359c-6.046.647-10.798 2.198-10.634 3.463.167 1.262 5.21 1.762 11.254 1.118 6.04-.65 10.809-2.196 10.636-3.463-.175-1.262-5.21-1.766-11.259-1.121l.003.003zM40.428 323.681c-6.046.642-10.825 1.934-10.693 2.886.133.95 5.15 1.197 11.196.555 6.043-.644 10.836-1.931 10.699-2.883-.142-.95-5.15-1.2-11.2-.558h-.002z"
            ></path>
            <path
                fill="#EFDDA9"
                d="M44.048 308.284c-4.62 0-8.366 3.727-8.366 8.33v3.898c0 4.603 3.746 8.33 8.366 8.33 4.623 0 8.363-3.73 8.363-8.33v-3.898c0-4.6-3.743-8.33-8.363-8.33z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M123.551 310.844H55.184v15.245h68.367v-15.245z"
            ></path>
            <path
                fill="#fff"
                d="M55.184 310.844H50.63v15.245h4.554v-15.245z"
            ></path>
            <path
                fill="#231F20"
                d="M541.091 266.702c.885 3.64 6.333 6.306 6.333 6.306s20.66 5.334 22.674 5.835c2.94.726 3.809-.182 3.809-.182l-17.623-17.543-3.934.427 1.866-2.484-3.615-3.599s-10.401 7.606-9.516 11.243l.006-.003z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M397.213 349.301c6.579 0 11.915-7.522 11.915-16.809s-5.336-16.822-11.915-16.822c-6.579 0-11.917 7.535-11.917 16.822 0 9.287 5.338 16.809 11.917 16.809z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M483.295 345.721v-26.482l-86.082-3.547v33.595l86.082-3.566z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M553.358 276.414l-13.748-13.689-65.714 60.399 18.8 18.718 60.662-65.428z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M492.981 342.122c5.349-5.326 5.349-13.957 0-19.284-5.35-5.326-14.022-5.329-19.371 0-5.35 5.327-5.35 13.961 0 19.287 5.352 5.326 14.021 5.326 19.371 0v-.003z"
            ></path>
            <path
                fill="#231F20"
                d="M506.805 222.07c1.415 3.469 7.199 5.305 7.199 5.305s21.226 2.233 23.292 2.432c3.013.286 3.74-.74 3.74-.74l-20.035-14.758-3.825 1.002 1.478-2.729-4.109-3.028s-9.158 9.054-7.743 12.519l.003-.003z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M376.774 324.941c6.508-.969 10.669-9.192 9.289-18.381-1.379-9.184-7.775-15.851-14.283-14.883-6.508.969-10.666 9.208-9.289 18.392 1.379 9.186 7.775 15.838 14.283 14.869v.003z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M461.378 308.725l-3.932-26.188-85.663 9.165 4.988 33.225 84.607-16.202z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M520.381 229.867l-15.628-11.513-56.025 69.409 21.374 15.742 50.279-73.638z"
            ></path>
            <path
                fill="#0D0D0D"
                d="M470.424 303.744c4.5-6.055 3.218-14.591-2.866-19.072-6.082-4.48-14.658-3.204-19.158 2.851-4.5 6.053-3.218 14.595 2.866 19.075 6.082 4.48 14.658 3.204 19.158-2.851v-.003z"
            ></path>
            <path
                fill="#414042"
                d="M401.738 68.646H209.184l-34.81 281.493h262.174l-34.81-281.493z"
            ></path>
            <path
                fill="#58595B"
                d="M209.184 68.646l-28.728 5.822-52.247 235.179 46.165 40.492 34.81-281.493z"
            ></path>
            <path
                fill="#6D6E71"
                d="M292.46 34.828c18.729 0 33.966 15.17 33.966 33.818h4.809c0-21.286-17.393-38.606-38.775-38.606-21.382 0-38.774 17.317-38.774 38.606h4.808c0-18.647 15.237-33.818 33.966-33.818z"
            ></path>
            <path
                fill="#58595B"
                d="M292.46 43.644c13.847 0 25.111 11.216 25.111 25h8.855c0-18.648-15.237-33.819-33.966-33.819s-33.966 15.171-33.966 33.819h8.855c0-13.787 11.265-25 25.111-25z"
            ></path>
            <path
                fill="#414042"
                d="M292.46 43.644c-13.846 0-25.111 11.216-25.111 25h3.202c0-12.027 9.828-21.812 21.907-21.812 12.078 0 21.906 9.785 21.906 21.812h3.202c0-13.787-11.265-25-25.111-25h.005z"
            ></path>
            <path
                fill="#231F20"
                d="M218.075 183.162h17.893c4.035 0 7.111.308 9.226.923 2.844.835 5.281 2.315 7.309 4.445 2.027 2.13 3.571 4.736 4.631 7.82 1.057 3.085 1.587 6.888 1.587 11.409 0 3.972-.497 7.399-1.489 10.275-1.213 3.512-2.943 6.357-5.194 8.528-1.699 1.646-3.991 2.932-6.879 3.852-2.161.683-5.049 1.02-8.664 1.02h-18.423V183.16l.003.002zm9.789 8.167v31.974h7.309c2.732 0 4.707-.155 5.92-.463 1.588-.394 2.904-1.063 3.954-2.007 1.046-.944 1.901-2.497 2.562-4.66.662-2.163.992-5.109.992-8.841 0-3.732-.33-6.597-.992-8.593-.661-1.997-1.587-3.556-2.778-4.676-1.191-1.121-2.702-1.877-4.53-2.272-1.366-.307-4.046-.462-8.035-.462h-4.402zM267.021 231.436v-48.274h35.95v8.166h-26.16v10.702h24.343v8.134h-24.343v13.139h27.086v8.133h-36.876zM312.233 183.162h19.382c3.836 0 6.696.161 8.581.479 1.885.318 3.571.982 5.06 1.991 1.489 1.009 2.727 2.356 3.721 4.034.992 1.681 1.489 3.561 1.489 5.648 0 2.26-.612 4.336-1.833 6.224-1.224 1.888-2.885 3.305-4.978 4.249 2.954.857 5.227 2.317 6.814 4.379 1.588 2.062 2.38 4.489 2.38 7.277 0 2.195-.511 4.331-1.538 6.404-1.025 2.075-2.426 3.732-4.2 4.972-1.773 1.241-3.964 2.003-6.565 2.288-1.631.177-5.568.286-11.808.329h-16.502v-48.274h-.003zm9.789 8.036v11.161h6.415c3.814 0 6.186-.054 7.112-.166 1.675-.198 2.992-.775 3.951-1.727.959-.955 1.439-2.212 1.439-3.77 0-1.559-.412-2.707-1.24-3.64-.828-.933-2.057-1.499-3.688-1.695-.97-.109-3.76-.163-8.369-.163h-5.623.003zm0 19.197v12.908h9.06c3.527 0 5.765-.101 6.713-.297 1.456-.264 2.639-.906 3.554-1.926.916-1.02 1.375-2.388 1.375-4.099 0-1.45-.353-2.68-1.058-3.689-.705-1.009-1.727-1.746-3.06-2.206-1.333-.46-4.229-.691-8.68-.691h-7.904zM372.029 231.436v-40.108h-14.388v-8.166h38.531v8.166h-14.354v40.108h-9.789z"
            ></path>
            <path
                fill="#D1D3D4"
                d="M216.414 181.582h17.893c4.035 0 7.111.307 9.226.922 2.844.835 5.281 2.318 7.308 4.445 2.028 2.13 3.571 4.736 4.631 7.821 1.058 3.085 1.588 6.888 1.588 11.409 0 3.974-.497 7.399-1.489 10.274-1.213 3.512-2.943 6.355-5.194 8.528-1.699 1.646-3.992 2.93-6.88 3.855-2.161.68-5.049 1.02-8.663 1.02h-18.423v-48.274h.003zm9.789 8.166v31.975h7.309c2.732 0 4.707-.153 5.92-.463 1.587-.394 2.904-1.064 3.953-2.007 1.047-.944 1.902-2.498 2.563-4.66.661-2.163.992-5.112.992-8.841 0-3.73-.331-6.597-.992-8.596-.661-1.997-1.587-3.556-2.778-4.677-1.192-1.12-2.702-1.876-4.53-2.271-1.366-.307-4.047-.462-8.038-.462h-4.399v.002zM265.36 229.856v-48.274h35.95v8.166h-26.161v10.702h24.341v8.133h-24.341v13.139h27.087v8.134H265.36zM310.572 181.582h19.381c3.836 0 6.697.16 8.582.479 1.885.318 3.571.982 5.06 1.991 1.489 1.009 2.727 2.353 3.721 4.034.992 1.681 1.489 3.561 1.489 5.647 0 2.261-.612 4.336-1.833 6.224-1.224 1.888-2.885 3.305-4.978 4.249 2.953.855 5.227 2.315 6.814 4.38 1.587 2.065 2.382 4.491 2.382 7.277 0 2.195-.51 4.331-1.538 6.403-1.024 2.076-2.426 3.733-4.199 4.973-1.776 1.241-3.964 2.002-6.565 2.288-1.631.177-5.568.285-11.809.329h-16.502v-48.274h-.005zm9.789 8.036v11.164h6.415c3.814 0 6.186-.055 7.112-.166 1.675-.199 2.994-.776 3.95-1.73.959-.955 1.44-2.212 1.44-3.771 0-1.559-.412-2.706-1.24-3.64-.828-.933-2.057-1.498-3.688-1.694-.97-.109-3.76-.163-8.369-.163h-5.623.003zm0 19.197v12.907h9.062c3.528 0 5.765-.1 6.713-.296 1.457-.264 2.64-.906 3.555-1.926s1.371-2.389 1.371-4.1c0-1.45-.355-2.676-1.057-3.688-.705-1.01-1.727-1.747-3.06-2.206-1.333-.46-4.229-.691-8.68-.691h-7.904zM370.367 229.856v-40.108H355.98v-8.166h38.529v8.166h-14.355v40.108h-9.789.002z"
            ></path>
            <path
                fill="#E6E7E8"
                d="M120.833 68.366a154.687 154.687 0 01-3.376-12.399c-1.513-7.185-2.153-13.5-3.258-20.742-1.047-6.025-2.328-11.47-4.306-17.033 1.688-.29 3.259-.811 5.063-1.1.175-.06.233-.116.407-.175 4.422-.812 8.146-1.739 12.511-2.49.175-.059.232-.116.407-.174 2.328-.348 4.364-.985 6.517-1.391 1.454 4.345 2.619 8.517 3.492 13.21.058.174.116.233.174.405.523 2.608.873 4.924 1.28 7.532.64 4.46 1.163 8.343 1.862 12.746 1.047 5.793 2.328 11.008 4.132 16.28-1.688.405-3.258.695-5.004 1.159-.582.115-1.106.174-1.688.29-.291.056-.524.173-.815.232-.407.058-.815.115-1.222.174-.175.058-.234.115-.407.174-.698.115-1.338.174-2.037.405-.407.058-.816.115-1.222.174-.291.058-.525.174-.816.23-.815.116-1.571.29-2.385.406-.524.174-.99.29-1.572.405-2.793.464-5.12 1.158-7.739 1.68l.002.002z"
            ></path>
            <path
                fill="#013C46"
                d="M129.503 48.61c-1.92.52-3.898.174-5.645-.754-.64-.405-1.163-.811-1.745-1.275-.406-.29-.581-.695-.931-1.043a7.44 7.44 0 01-.931-1.621c-.116-.349-.291-.754-.407-1.102-.175-.522-.175-1.1-.291-1.68.058-.174 0-.348 0-.58.059-.289.059-.521.116-.81 0-.116-.058-.175-.058-.29 0-.349.232-.695.29-.986.932-4.056 4.773-6.489 8.787-6.084.407.059.698.116 1.106.174l1.047.348c.523.174.931.464 1.454.753.291.174.582.464.873.638 1.571 1.332 2.56 3.128 2.909 5.214.059.637 0 1.275 0 1.97 0 .579-.174 1.1-.349 1.621-.232.695-.466 1.276-.873 1.912-1.28 1.854-3.141 3.245-5.353 3.591l.001.003z"
            ></path>
            <path
                fill="#013C46"
                d="M142.596 57.937c-2.794-8.458-3.316-16.976-4.945-26.13-.232-1.39-.407-2.49-.641-3.765-.059-.29-.175-.522-.233-.812-.873-4.229-1.804-8.169-3.258-12.05-.466 0-.873.29-1.338.29-.407.058-.698.23-1.047.348-6.634 1.39-12.744 2.492-19.086 4.055a137.45 137.45 0 011.629 5.213c.058.406.174.754.232 1.159.697 2.318 1.106 4.462 1.629 6.837 0 .23-.059.52.058.753.232.579.232 1.274.35 1.97 1.222 7.242 1.804 13.615 3.433 20.683.815 3.36 1.687 6.43 2.734 9.56.757-.29 1.572-.405 2.445-.579.29-.058.406-.174.697-.233 1.222-.174 2.211-.463 3.376-.694.29-.059.523-.057.815-.116.175-.058.232-.115.407-.174 1.745-.348 3.375-.579 5.12-1.042l1.222-.175c.175-.058.232-.115.407-.174 2.501-.405 4.713-.984 7.041-1.564-.232-1.159-.815-2.201-1.047-3.361v.001zm-20.832 3.94c-.989-3.535-1.863-6.952-2.503-10.718-.116-.464-.175-.753-.291-1.217-.697-3.824-.931-7.185-1.513-11.008-.523-3.07-.815-5.91-1.629-8.922-.058-.29-.058-.522-.116-.812-.465-2.085-1.047-4.055-1.629-6.025 1.572-.174 2.677-1.854 2.211-3.36.291-.058.582-.116.815-.232l1.222-.174c.175-.058.232-.115.407-.174 4.016-.753 7.623-1.447 11.463-2.26.581 1.392 2.094 2.782 3.723 2.319a109.38 109.38 0 012.91 12.862c1.279 7.88 1.804 14.89 3.782 22.538.291 1.1.698 2.2.931 3.36-1.454.29-2.676 1.855-2.153 3.303-.524.29-1.047.23-1.629.348-.175.058-.232.115-.407.174a145.88 145.88 0 00-7.564 1.506c-1.513.348-2.967.522-4.364.869-.466-1.507-2.094-2.665-3.666-2.375v-.002z"
            ></path>
            <path
                fill="#E6E7E8"
                d="M124.237 40.498c.988-.405 2.094-.348 3.141-.58.175-1.564.35-3.36 1.863-4.17.349-.116.698-.231 1.047-.231.291.057.582 0 .815.115l.698.348c.815.58 1.281 1.391 1.629 2.318 0 .116.059.29.059.405.233-.115.465-.115.697-.232.175.405.232.927.35 1.39-.232.116-.466.116-.698.232.232 1.621-.174 3.65-1.862 4.287-.116-.811-.641-1.447-.757-2.259.466-.348.932-.812.99-1.448-.931.116-1.978.348-2.909.58-.116 1.738-.232 4.055-2.386 4.577-.465.115-.872.058-1.338-.059-.175-.058-.407-.174-.581-.232-1.164-.695-1.863-1.97-2.212-3.244-.407.29-.932.348-1.339.522-.116-.464-.174-.984-.29-1.448.406-.174.872-.174 1.28-.463-.174-1.912.407-4.114 2.445-4.75.233.811.581 1.622.815 2.433a2.456 2.456 0 00-1.454 1.912l-.003-.003zm7.739-1.333c-.232-.694-.989-1.332-1.745-.927-.524.29-.582.927-.757 1.448.816-.115 1.745-.233 2.502-.522v.001zm-7.623 2.723c.174.638.581 1.276 1.222 1.448.581.116 1.106-.174 1.397-.694.174-.406.174-.87.232-1.276-.932.231-1.92.29-2.851.522z"
            ></path>
            <path
                fill="#fff"
                d="M108.243 193.342c-11.547-4.299-22.21-10.541-31.765-18.562-5.735-4.953-10.917-10.195-16.41-15.488-6.058-5.697-12.462-10.441-20.186-14.068-2.602-1.171-5.058-2.067-7.628-3.043-.846-.261-1.658-.327-2.423-.702 1.24-2.201 2.677-4.435 4.031-6.554 1.762-2.687 3.639-5.292 5.4-7.98 3.477-5.064 7.294-9.887 10.656-15.034 1.926-2.913 3.934-5.94 5.631-9.016 12.198 4.59 23.545 11.32 33.423 20.088 2.714 2.452 5.314 4.823 7.944 7.388 1.575 1.641 3.234 3.166 4.809 4.806 9.325 9.058 19.842 16.225 32.236 19.585-1.436 2.234-2.79 4.353-4.114 6.668-1.6 2.459-3.393 4.953-4.993 7.412-3.639 5.293-7.537 10.227-11.063 15.602-1.926 2.913-3.85 5.826-5.55 8.902l.002-.004z"
            ></path>
            <path
                fill="#013C46"
                d="M93.581 157.49c-.571.793-1.174 1.391-1.972 2.022-.08.113-.245.339-.326.453-4.27 3.298-9.968 4.032-15.106 2.079-1.301-.585-2.716-1.254-3.772-2.179-.714-.682-1.315-1.282-2.03-1.963-.974-1.038-1.672-2.224-2.287-3.521-1.328-3.178-1.567-6.436-.91-9.74.311-1.151.704-2.413 1.323-3.513.49-.681.783-1.329 1.274-2.007.847-.937 1.695-1.877 2.657-2.733.165-.228.555-.29.832-.437 1.598-1.26 3.486-1.969 5.408-2.485l1.758-.287c7.294-.797 14.427 4.125 16.125 11.339l.289 1.751c.498 3.614-.322 7.146-2.33 10.172-.245.34-.457.876-.928 1.051l-.005-.002z"
            ></path>
            <path
                fill="#013C46"
                d="M123.175 153.233c-9.401-3.453-17.315-9.447-24.528-16.655-9.276-9.365-18.308-17.873-30.146-23.922-3.17-1.577-6.113-2.994-9.334-4.262-.731-.18-1.496-.554-2.227-.732-1.698 3.076-3.82 6.02-5.746 8.933-2.629 4.128-5.629 7.816-8.37 11.863-2.088 3.141-4.21 6.085-6.186 9.306-.62 1.101-1.354 2.121-1.861 3.302 10.086 2.741 19.14 8.348 26.938 15.459.34.244.519.713.86.958.57.405.941.844 1.51 1.249 4.809 4.806 9.472 9.335 14.525 13.8 1.885 1.688 4.014 3.037 6.061 4.499 1.251.894 2.421 1.9 3.755 2.682 1.79 1.105 3.576 2.209 5.446 3.202 2.18 1.041 4.081 2.229 6.342 3.155 2.261.927 4.407 1.773 6.554 2.622 5.126-9.033 11.813-17.122 17.508-25.75 1.6-2.46 3.167-5.114 4.767-7.574-1.951-.879-4.002-1.142-5.87-2.133l.002-.002zm-23.167 30.971c-8.228-3.645-15.673-8.617-22.497-14.689-9.083-8.199-16.455-17.579-27.119-23.819-1.789-1.105-3.74-1.984-5.608-2.975-.991-.536-1.951-.879-2.911-1.221 1.255-1.504 1.733-4.079.27-5.637-.57-.406-.944-.844-1.675-1.024 4.831-7.186 9.92-14.015 14.977-21.039.081-.114.391-.064.505.017 2.57.976 6.118.593 7.832-1.784 9.756 4.392 18.323 10.68 26.186 18.179 3.72 3.685 7.017 7.24 10.82 10.813 6.287 5.861 13.113 10.733 21.132 13.714-1.386 1.926-1.766 5.085.557 6.401.228.164.536.213.845.261-4.716 7.268-10.115 14.046-15.06 21.15-1.072-.424-2.228-.732-3.514-.623-.276.145-.586.096-.977.162-1.448.337-2.979.788-3.763 2.116v-.002z"
            ></path>
            <path
                fill="#fff"
                d="M76.486 151.803c1.029-1.668 2.625-2.927 4.108-4.27-1.394-2.369-3.36-5.143-2.134-8.042.507-1.181 1.386-1.926 2.527-2.313.31.05.586-.095.977-.159 2.098-.046 4.018.639 5.724 1.858.246-.34.376-.762.393-1.264.796.568 1.48 1.055 2.275 1.624-.13.42-.457.875-.588 1.295 2.145 2.046 3.896 5.354 2.36 8.203-1.219-.699-2.6-1.171-3.656-2.098.326-.453.23-1.037.361-1.459-.033-.194-.095-.583-.16-.972-.064-.389-.21-.664-.438-.828a35.693 35.693 0 01-3.522 4.176c1.313 2.48 3.863 5.159 2.36 8.203-.457.875-1.142 1.586-1.974 2.021-1.188.695-2.67.838-4.02.559a11.575 11.575 0 01-2.878-1.027c-.536-.211-.83-.763-1.447-.86-.49.678-.752 1.52-1.126 2.282-.681-.486-1.315-1.283-2.08-1.657.376-.76.638-1.604 1.126-2.282-.6-.6-1.2-1.2-1.608-1.833a6.994 6.994 0 00-.55-.909c-.616-1.297-1.152-2.708-.904-4.247.05-.308.293-.648.228-1.037.05-.308.295-.647.343-.955l.326-.454c1.041.228 1.951.88 2.943 1.416.34.244.683.488 1.024.73a5.027 5.027 0 00.008 4.295l.002.004zm9.719-10.386c-1.22-.699-3.319-.654-3.535 1.08.015.697.308 1.248.437 2.028.033.194.26.356.293.55 1.01-1.165 2.136-2.249 2.805-3.658zm-7.884 12.382c1.056.925 2.83 1.335 3.954.251.162-.226.44-.373.49-.681.231-1.036-.321-1.945-.677-2.886-.03-.194-.26-.356-.374-.439-1.32 1.116-2.526 2.313-3.39 3.753l-.003.002z"
            ></path>
            <path
                fill="#D1D3D4"
                d="M422.396 57.73a189.146 189.146 0 015.426-14.739c3.585-8.228 7.425-14.977 11.457-22.977 3.226-6.746 5.811-13.077 7.782-20.014 1.889.897 3.825 1.482 5.83 2.46.214.066.314.048.529.114 4.986 2.299 9.357 3.993 14.243 6.31.215.065.315.047.529.112 2.569 1.29 5.051 2.086 7.486 3.194-1.629 5.356-3.426 10.334-5.884 15.628-.066.215-.048.313-.113.527-1.326 2.968-2.622 5.525-4.065 8.412-2.525 4.899-4.755 9.138-7.18 14.021-3.063 6.515-5.484 12.614-7.424 19.141-1.971-.782-3.742-1.597-5.811-2.361-.663-.294-1.227-.604-1.89-.898-.33-.147-.645-.194-.977-.34-.447-.23-.894-.458-1.342-.686-.215-.066-.314-.048-.529-.113-.779-.375-1.458-.767-2.32-1.028-.447-.228-.893-.457-1.342-.685-.331-.147-.645-.194-.977-.341-.896-.457-1.773-.816-2.667-1.273a15.135 15.135 0 01-1.855-.7c-3.115-1.501-5.929-2.443-8.91-3.765h.004z"
            ></path>
            <path
                fill="#013C46"
                d="M445.052 44.12c-2.286-.83-4.012-2.568-5.096-4.719-.351-.854-.585-1.627-.837-2.498-.201-.575-.088-1.102-.189-1.694a9.003 9.003 0 01.222-2.272c.131-.429.244-.955.374-1.384.195-.642.606-1.22.901-1.88.182-.133.246-.348.411-.58.264-.247.428-.478.692-.726.082-.116.065-.214.147-.33.247-.346.726-.529.989-.777 3.805-3.39 9.361-3.118 13.078.11.365.344.613.607.978.951.267.36.532.722.799 1.083.399.541.6 1.117.916 1.774.167.378.252.872.419 1.249.621 2.432.335 4.92-.796 7.246-.393.676-.904 1.271-1.397 1.964-.41.578-.955.974-1.499 1.372-.725.53-1.367.943-2.226 1.293-2.59.95-5.434 1.028-7.886-.18v-.002z"
            ></path>
            <path
                fill="#013C46"
                d="M451.496 62.626c3.212-10.4 8.729-19.263 13.595-29.538.753-1.55 1.36-2.77 2.032-4.205.147-.33.195-.642.342-.973 2.129-4.831 3.993-9.416 5.295-14.31-.465-.327-1.076-.325-1.54-.65-.447-.23-.862-.26-1.292-.39-7.602-3.278-14.476-6.474-21.91-9.373a169.533 169.533 0 01-2.073 6.346c-.229.445-.36.874-.59 1.319-.947 2.802-2.06 5.226-3.222 7.964-.164.231-.428.479-.475.792-.179.741-.672 1.434-1.049 2.21-3.917 8.081-7.854 14.846-11.242 23.042-1.57 3.923-2.877 7.6-4.051 11.455.96.244 1.855.7 2.849 1.141.332.147.529.113.861.26 1.342.685 2.534 1.091 3.858 1.679.333.147.564.31.896.457.216.065.314.047.529.112 1.987.882 3.776 1.795 5.846 2.56l1.342.684c.216.066.314.048.53.113 2.783 1.354 5.399 2.33 8.132 3.39.589-1.32.747-2.768 1.337-4.088v.003zm-23.57-10.715c1.52-4.22 3.072-8.242 5.104-12.447.214-.545.361-.874.573-1.419 2.015-4.304 4.165-7.82 6.295-12.041 1.656-3.431 3.378-6.467 4.702-10.044.148-.329.311-.56.459-.89 1.015-2.407 1.831-4.78 2.647-7.154 1.69.932 3.985.033 4.589-1.797.331.146.663.293.977.34.447.23.894.458 1.342.686.216.065.315.047.531.113 4.538 2.07 8.629 3.913 13.035 5.804-.405 1.796.118 4.245 2.071 4.929a133.667 133.667 0 01-6.218 14.873c-4.31 8.76-8.759 16.118-12.207 25.137-.491 1.302-.866 2.686-1.454 4.006-1.657-.734-3.984-.033-4.489 1.78-.729-.079-1.21-.504-1.871-.798-.216-.065-.315-.048-.531-.113a177.412 177.412 0 00-8.612-3.815c-1.755-.716-3.33-1.566-4.969-2.201.604-1.83-.199-4.131-1.972-4.947l-.002-.002z"
            ></path>
            <path
                fill="#D1D3D4"
                d="M445.551 32.328c1.273.29 2.336 1.125 3.544 1.63 1.284-1.438 2.731-3.106 4.815-2.851.43.129.861.26 1.208.506.249.262.581.41.73.688.15.279.301.558.45.838.402 1.151.29 2.287-.018 3.456-.082.116-.147.33-.229.445.314.048.546.212.861.26-.114.526-.425 1.088-.638 1.631-.314-.047-.547-.212-.861-.26-.918 1.782-2.762 3.52-4.897 2.967.459-.89.389-1.894.848-2.785.71-.02 1.503-.154 2.014-.75-1.01-.538-2.22-1.044-3.312-1.466-1.348 1.652-3.108 3.882-5.624 2.888-.547-.212-.912-.557-1.293-.999-.133-.18-.283-.46-.416-.64-.667-1.512-.46-3.274.096-4.79-.613.003-1.176-.309-1.705-.421.213-.545.525-1.105.738-1.65.529.113.994.44 1.606.438 1.181-2.03 3.322-3.817 5.806-3.02-.343.973-.571 2.027-.914 3a3.022 3.022 0 00-2.806.883l-.003.002zm8.663 4.11c.26-.858-.042-2.025-1.083-2.15-.729-.08-1.238.514-1.782.911.894.457 1.905.996 2.865 1.239zm-9.533-2.642c-.279.758-.324 1.68.192 2.304.497.525 1.226.603 1.886.288.462-.282.789-.745 1.135-1.109-1.093-.422-2.12-1.06-3.213-1.484v.001z"
            ></path>
            <path
                fill="#fff"
                d="M548.181 148.495c-6.997 10.373-15.717 19.417-25.947 26.947-6.279 4.49-12.714 8.363-19.272 12.529-7.096 4.632-13.322 9.828-18.751 16.59-1.778 2.288-3.247 4.5-4.825 6.804-.458.772-.715 1.56-1.269 2.224-1.896-1.742-3.78-3.684-5.571-5.52-2.255-2.372-4.405-4.835-6.661-7.206-4.22-4.622-8.117-9.522-12.443-14.049-2.443-2.587-4.981-5.278-7.64-7.678 7.441-10.944 16.808-20.547 27.855-28.222 3.077-2.097 6.049-4.102 9.22-6.092 2.002-1.167 3.91-2.441 5.912-3.608 11.208-7.061 20.812-15.741 27.075-27.172 1.884 1.941 3.672 3.778 5.662 5.628 2.07 2.156 4.126 4.514 6.196 6.67 4.406 4.835 8.396 9.842 12.91 14.585 2.444 2.586 4.888 5.171 7.547 7.57h.002z"
            ></path>
            <path
                fill="#013C46"
                d="M509.066 154.505c.653.75 1.107 1.486 1.546 2.421l.374.428c2.274 4.987 1.663 10.779-1.492 15.384-.89 1.146-1.887 2.383-3.056 3.207-.847.543-1.586.995-2.431 1.54-1.263.716-2.606 1.126-4.04 1.426-3.475.561-6.77.028-9.903-1.398-1.071-.579-2.234-1.264-3.183-2.135-.562-.643-1.135-1.086-1.697-1.729-.733-1.056-1.466-2.115-2.092-3.264-.187-.215-.157-.615-.237-.922-.877-1.872-1.139-3.901-1.197-5.916.042-.601.084-1.202.128-1.801.925-7.378 7.503-13.253 15.077-13.225.604.041 1.207.083 1.811.127 3.712.36 7.031 2.001 9.569 4.693.281.322.761.657.827 1.164h-.004z"
            ></path>
            <path
                fill="#013C46"
                d="M511.805 124.321c-5.648 8.455-13.475 14.847-22.343 20.26-11.501 6.94-22.093 13.841-30.898 24.086-2.316 2.756-4.418 5.324-6.438 8.199-.352.678-.903 1.345-1.255 2.023 2.659 2.4 5.089 5.185 7.533 7.771 3.486 3.563 6.448 7.393 9.826 11.049 2.632 2.8 5.06 5.585 7.799 8.292.949.872 1.79 1.835 2.845 2.612 5.104-9.298 12.812-16.904 21.724-22.918.324-.278.831-.344 1.155-.623.537-.465 1.061-.73 1.598-1.195 5.913-3.609 11.515-7.138 17.148-11.069 2.124-1.461 3.966-3.243 5.905-4.918 1.182-1.023 2.459-1.94 3.55-3.07 1.521-1.502 3.041-3.006 4.468-4.616 1.549-1.903 3.177-3.499 4.632-5.508a560.103 560.103 0 004.152-5.845c-7.777-7.184-14.247-15.683-21.486-23.332-2.07-2.157-4.34-4.328-6.41-6.484-1.333 1.717-2.078 3.676-3.505 5.286zm25.346 30.14c-5.564 7.253-12.264 13.423-19.911 18.721-10.296 7.022-21.362 12.081-30.083 21.125-1.521 1.502-2.854 3.219-4.282 4.831-.768.851-1.333 1.717-1.901 2.582-1.2-1.593-3.65-2.67-5.544-1.595-.538.465-1.061.73-1.413 1.408-6.008-6.456-11.601-13.084-17.396-19.727-.093-.108.028-.4.136-.494 1.577-2.303 2.032-5.892.07-8.142 6.668-8.585 14.94-15.55 24.253-21.536 4.542-2.799 8.854-5.212 13.304-8.121 7.311-4.818 13.767-10.401 18.62-17.605 1.589 1.821 4.642 2.941 6.498.959.216-.187.338-.479.458-.772 6.116 6.363 11.587 13.284 17.489 19.833-.675.959-1.255 2.025-1.447 3.319.08.306-.041.6-.071 1-.006 1.508.081 3.124 1.218 4.21l.002.004z"
            ></path>
            <path
                fill="#fff"
                d="M499.381 170.021c-1.415-1.407-2.294-3.279-3.279-5.057-2.685.817-5.906 2.1-8.5.208-1.057-.777-1.588-1.82-1.704-3.035.122-.293.042-.601.07-1.001.449-2.081 1.582-3.812 3.197-5.209-.28-.32-.67-.549-1.163-.684l2.152-1.86c.387.229.761.657 1.149.885 2.539-1.633 6.244-2.58 8.716-.396-.984 1.039-1.779 2.29-2.948 3.114-.373-.428-.977-.472-1.364-.698l-1.005-.07c-.402-.028-.712.05-.926.237a36.26 36.26 0 013.321 4.456c2.778-.71 6.043-2.594 8.716-.396.761.657 1.309 1.5 1.547 2.421.411 1.337.203 2.831-.392 4.097a11.769 11.769 0 01-1.698 2.596c-.338.479-.954.636-1.197 1.223.561.643 1.336 1.1 2.004 1.65-.646.559-1.587.994-2.138 1.661-.668-.549-1.445-1.007-2.004-1.65-.74.452-1.479.902-2.204 1.152-.308.08-.618.158-1.033.331-1.435.302-2.965.495-4.438-.11-.296-.121-.576-.443-.977-.471-.294-.122-.576-.442-.869-.563l-.374-.43c.474-.973 1.335-1.717 2.1-2.568l.969-.838a5.171 5.171 0 004.272 1.005zm-8.038-12.03c-.983 1.038-1.433 3.117.239 3.738.698.149 1.315-.008 2.118.047.202.014.416-.171.618-.157-.921-1.271-1.732-2.636-2.977-3.628h.002zm10.458 10.691c1.169-.824 1.994-2.475 1.181-3.84-.188-.215-.266-.521-.562-.642-.977-.472-2.01-.142-3.029-.012-.2-.014-.416.171-.523.264.799 1.566 1.704 3.036 2.935 4.23h-.002z"
            ></path>
        </svg>
    );
}

export default MainImg;
