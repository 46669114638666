import React from "react";

function MainImg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            fill="none"
            viewBox="0 0 501 534"
        >
            <circle cx="254" cy="287" r="247" fill="#FEF7ED"></circle>
            <g clipPath="url(#clip0_27_25)">
                <path
                    fill="#DDA66F"
                    fillRule="evenodd"
                    d="M477.092 254.692c-5.511 11.165-13.831 21.551-25.672 30.485-16.374 12.358-39.48 21.928-71.165 26.921-38.35 6.04-109.74 30.966-130.324 2.705-.063-.088-.126-.173-.183-.259-15.127-21.345 28.276-32.236 12.359-56.363a85.578 85.578 0 01-5.619-9.896c-13.727-28.374-11.195-61.505-8.013-82.017 7.337-47.359 65.155-96.713 80.98-114.386 5.129-5.73-19.949-2.124-16.252-27.297 1.783-10.913-15.847-28.902 9.499-19.38 8.118.775 3.95 2.955 16.552 1.944 9.739-.778 8.061-7.206 18.537-7.149 7.103.035 14.709 7.339 14.782 12.443.12 8.675-23.545 16.747-21.488 35.555.616 5.632 28.026 21.14 57.129 42.21 24.898 18.028 51.037 40.142 62.678 63.633 14.4 29.07 22.262 68.279 6.2 100.851z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#fff"
                    d="M477.048 254.369c-5.511 11.166-13.831 21.551-25.672 30.486h-.003c32.143-52.811 12.005-127.016-43.2-194.972 24.897 18.031 51.037 40.141 62.678 63.633 14.4 29.07 22.265 68.275 6.2 100.85l-.003.003z"
                    opacity="0.15"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M294.579 265.772c-3.11 8.982-39.895 31.361-45.292 48.858-15.127-21.346 28.275-32.237 12.358-56.364a85.788 85.788 0 01-5.618-9.895l.341.072c14.675 8.748 41.582 7.579 38.211 17.326v.003zM333.743 57.849s-9.401 17.114-15.73 23.343c-6.33 6.23-7.799 7.531-7.799 7.531s9.979-3.018 12.959-7.389c2.98-4.37 11.926-24.528 11.926-24.528s-5.638 30.77-1.289 29.525c4.348-1.245 4.838-30.053 4.838-30.053s5.299 25.271 12.671 30.42c7.451 5.202-3.896-7.63-4.964-12.582-.323-1.492-6.083-18.081-6.083-18.081s21.87 17.55 23.08 22.66c1.21 5.11 5.217 9.2 5.217 9.2s3.255-3.533 1.817-8.128c-1.438-4.596-25.172-25.037-25.172-25.037l-10.542-2.895-.932 6.01.003.004zM330.773 48.996c-8.131-15.163-5.761-21.794-3.255-16.898 2.506 4.895 4.155 15.53 6.775 8.454 2.62-7.077 4.43-2.845 6.285.581 1.855 3.426 3.994-.97 5.391-3.03 1.394-2.061 2.759-2.213 1.027 5.334s-16.223 5.56-16.223 5.56z"
                    opacity="0.2"
                ></path>
                <path
                    fill="#3B3B4C"
                    fillRule="evenodd"
                    d="M327.066 54.063c-1.615-13.195 14.991-5.819 23.564-5.977 9.531.076 5.021 11.248-2.762 8.297-10.801 2.468-17.793 4.977-20.802-2.32z"
                    clipRule="evenodd"
                ></path>
                <path
                    fill="#EFAEA6"
                    d="M389.254 308.887c-.843-.623-1.311-2.089-2.433-1.435-.303.177.749 2.153.749 2.153l1.684-.718z"
                ></path>
                <path
                    fill="#3B3B4C"
                    d="M286.119 234.556c12.817.771 45.633 4.577 44.954 6.264-.926 2.295-5.862 5.721-10.652 6.147-4.212.376-2.62-5.619-15.351-2.898-8.712 1.862-19.469-4.209-24.329-3.255-4.86.954-13.199-3.682-9.559-3.353 4.876.439 14.941-2.905 14.941-2.905h-.004z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M240.328 272.716c-3.561-3.161-7.546-9.624-11.891-10.098-5.492-.6-18.28 5.733-26.648 6.77-12.469 1.542-19.699-.389-20.631.578-.932.967 1.273 11.45 5.435 11.612 4.917.189 14.327 1.618 21.962-1.47 6.724-2.721 12.368-5.066 16.571-5.698 4.894-.737 16.498-.547 15.202-1.694z"
                ></path>
                <path
                    fill="#fff"
                    d="M205.847 275.468c2.6.519 5.748.462 8.083-.72s2.503-2.775.376-3.559c-2.13-.784-5.777-.683-9.173.948-2.402 1.154-2.013 2.788.717 3.331h-.003z"
                    opacity="0.34"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.58"
                    d="M205.847 275.468c2.6.519 5.748.462 8.083-.72s2.503-2.775.376-3.559c-2.13-.784-5.777-.683-9.173.948-2.402 1.154-2.013 2.788.717 3.331h-.003z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.3"
                    d="M226.62 264.047c-2.357-.14-13.486 5.388-22.104 6.478-8.576 1.084-18.798 1.265-19.639 1.053-3.027-.771-.268 8.423 3.726 8.577 1.305.051 9.755 1.069 16.71-.945 4.019-1.163 14.374-5.154 18.308-5.982 10.065-2.118 13.964-.389 13.171-1.075-3.634-3.154-7.214-7.933-10.172-8.106z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M193.03 276.366c.793.97 2.86 1.353 4.617.86 1.756-.497 2.537-1.682 1.744-2.652-.793-.97-2.86-1.353-4.617-.86-1.757.497-2.537 1.682-1.744 2.652zM220.439 270.734c.964.79 2.971 1.018 4.478.512 1.507-.506 1.947-1.558.983-2.345-.964-.79-2.971-1.018-4.478-.512-1.507.506-1.946 1.558-.983 2.345zM228.267 264.59c-2.81.92-8.858 3.236-9.714 3.053-.856-.186-1.384-.55-1.384-.55l8.797-3.028 1.394.089.91.439-.003-.003zM199.167 280.155c-.639-.512-1.094-1.077-1.751-1.106-.657-.025-11.578-.622-11.174-.224.405.398 1.609 1.343 1.609 1.343l3.217.161 5.258.111 2.841-.285z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M57.407 406.969c-2.654-3.951-4.895-11.207-8.977-12.756-5.167-1.959-19.134.967-27.495-.13-12.457-1.634-18.973-5.319-20.117-4.614-1.143.705-1.636 11.406 2.348 12.604 4.715 1.419 13.465 5.161 21.627 4.083 7.192-.948 13.243-1.801 17.472-1.359 4.923.516 16.106 3.61 15.142 2.172z"
                ></path>
                <path
                    fill="#fff"
                    d="M23.34 400.986c2.385 1.154 5.447 1.887 8.004 1.327 2.556-.559 3.119-2.06 1.254-3.35-1.864-1.292-5.422-2.111-9.12-1.381-2.616.515-2.648 2.194-.142 3.404h.004z"
                    opacity="0.34"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.58"
                    d="M23.34 400.986c2.385 1.154 5.447 1.887 8.004 1.327 2.556-.559 3.119-2.06 1.254-3.35-1.864-1.292-5.422-2.111-9.12-1.381-2.616.515-2.648 2.194-.142 3.404h.004z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.3"
                    d="M46.313 395.139c-2.247-.724-14.41 1.833-23.02.727-8.574-1.1-18.515-3.489-19.274-3.906-2.736-1.505-2.37 8.087 1.454 9.238 1.251.376 9.174 3.479 16.413 3.277 4.18-.117 15.206-1.384 19.222-1.201 10.273.474 13.613 3.126 13.02 2.263-2.728-3.963-4.994-9.488-7.816-10.398z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M10.706 398.641c.524 1.138 2.43 2.029 4.253 1.991 1.824-.038 2.879-.992 2.355-2.13-.525-1.138-2.43-2.029-4.254-1.991-1.823.038-2.879.992-2.354 2.13zM38.653 400.063c.736 1.005 2.62 1.729 4.206 1.618 1.586-.114 2.275-1.017 1.539-2.026-.737-1.005-2.62-1.728-4.206-1.618-1.587.114-2.276 1.018-1.54 2.026zM47.77 396.081c-2.952.183-9.389.913-10.17.518-.783-.395-1.203-.878-1.203-.878l9.274-.724 1.324.433.771.651h.003zM15.696 403.846c-.49-.657-.79-1.318-1.42-1.511-.628-.189-11.053-3.508-10.762-3.018.29.487 1.22 1.704 1.22 1.704l3.074.96 5.063 1.426 2.821.436.004.003z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M200.756 348.627c-4.282-2.08-9.865-7.225-14.176-6.511-5.451.907-16.052 10.458-23.829 13.716-11.588 4.855-19.074 4.947-19.709 6.129-.638 1.185 4.32 10.682 8.368 9.712 4.787-1.144 14.232-2.314 20.748-7.348 5.742-4.438 10.539-8.221 14.416-9.962 4.516-2.029 15.737-4.981 14.179-5.74l.003.004z"
                ></path>
                <path
                    fill="#fff"
                    d="M168.303 360.592c2.645-.202 5.656-1.109 7.587-2.879s1.662-3.35-.6-3.527c-2.26-.18-5.748.904-8.576 3.391-2.001 1.76-1.185 3.227 1.589 3.015z"
                    opacity="0.34"
                ></path>
                <path
                    fill="#87C9A6"
                    d="M167.785 360.44c-1.056 0-1.77-.287-1.956-.787-.193-.508.174-1.216.998-1.946 2.825-2.488 6.058-3.369 7.964-3.369.167 0 .328.006.483.018.913.073 1.507.386 1.678.879.212.613-.218 1.47-1.182 2.351-1.684 1.543-4.481 2.605-7.483 2.832a6.851 6.851 0 01-.502.019v.003z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M174.791 354.511c.161 0 .319.007.47.019.841.067 1.381.338 1.53.762.183.537-.24 1.346-1.135 2.168-1.655 1.517-4.414 2.56-7.381 2.788a7.047 7.047 0 01-.49.019c-.964 0-1.637-.253-1.795-.677-.161-.43.193-1.087.951-1.754 2.79-2.456 5.972-3.325 7.85-3.325zm0-.344c-2.254 0-5.448 1.097-8.077 3.41-1.871 1.647-1.28 3.034 1.071 3.034.164 0 .338-.006.518-.022 2.645-.202 5.656-1.109 7.587-2.879s1.662-3.35-.6-3.527a6.182 6.182 0 00-.499-.019v.003z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.3"
                    d="M185.218 343.984c-2.306.506-11.53 8.83-19.531 12.209-7.963 3.359-17.759 6.295-18.625 6.318-3.122.075 2.016 8.182 5.903 7.253 1.27-.304 9.679-1.606 15.834-5.424 3.555-2.206 12.447-8.846 16.012-10.704 9.116-4.76 13.338-4.143 12.39-4.592-4.351-2.054-9.088-5.689-11.983-5.057v-.003z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M156.204 364.919c1.027.717 3.118.531 4.676-.421 1.558-.951 1.991-2.304.964-3.024-1.027-.718-3.119-.531-4.677.42-1.558.952-1.99 2.304-.963 3.025zM181.076 352.09c1.14.5 3.134.177 4.449-.717 1.314-.894 1.453-2.026.313-2.525-1.141-.5-3.135-.177-4.449.717-1.315.895-1.454 2.026-.313 2.525zM186.95 344.063c-2.458 1.643-7.653 5.512-8.529 5.562-.875.054-1.482-.155-1.482-.155l7.654-5.293 1.365-.291.995.177h-.003zM163.133 366.91c-.755-.319-1.346-.743-1.984-.591-.638.151-11.316 2.528-10.82 2.803.496.275 1.912.857 1.912.857l3.141-.715 5.094-1.314 2.661-1.043-.004.003z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M287.061 231.042c-5.549 4.892-30.412 26.677-34.355 27.464-3.944.787 16.944 10.025 21.112 8.059 4.164-1.965 34.627-27.303 32.554-30.008-2.073-2.705-16.723-7.8-19.311-5.515z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.48"
                    d="M287.096 232.9c-4.813 4.245-27.173 24.781-30.592 25.461-3.422.682 14.508 7.585 18.119 5.878 3.612-1.706 30.497-24.629 28.699-26.974-1.798-2.345-13.979-6.346-16.226-4.368v.003z"
                ></path>
                <path
                    fill="#87C9A6"
                    d="M278.428 252.644c-1.716 0-3.065-.572-3.609-1.53-.395-.695-.331-1.52.184-2.386 2.101-3.543 5.959-4.798 8.62-4.798.186 0 .37.006.55.016 1.637.101 2.891.733 3.441 1.732.455.825.392 1.849-.177 2.882-1.182 2.156-4.25 3.666-8.21 4.046-.271.025-.54.038-.799.038z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M283.623 244.098c.18 0 .363.006.54.016 1.577.097 2.781.695 3.299 1.64.424.771.361 1.735-.177 2.715-1.153 2.104-4.174 3.584-8.073 3.957a8.385 8.385 0 01-.784.037c-1.653 0-2.945-.54-3.46-1.444-.364-.638-.3-1.403.18-2.212 2.067-3.48 5.855-4.716 8.472-4.716m0-.344c-3.132 0-6.81 1.583-8.769 4.883-1.359 2.285.411 4.178 3.574 4.178.262 0 .534-.013.815-.041 3.492-.335 6.98-1.65 8.342-4.134 1.366-2.487-.158-4.665-3.403-4.867a8.058 8.058 0 00-.562-.016l.003-.003zM270.089 258.737c1.697.54 3.887-.057 4.891-1.337 1.005-1.28.443-2.756-1.254-3.296-1.697-.541-3.887.057-4.892 1.337-1.005 1.28-.442 2.755 1.255 3.296zM288.979 242.148c1.656.294 3.631-.424 4.405-1.596.777-1.176.063-2.364-1.596-2.658-1.655-.294-3.63.423-4.405 1.596-.774 1.172-.063 2.364 1.596 2.658zM290.047 232.395s-6.601 6.788-8.273 7.414c-1.671.626-2.866.423-2.866.423l8.662-7.758 2.477-.079zM285.519 255.785c-2.259.281-3.305.095-4.282.787-.976.692-10.358 7.781-9.739 7.844.623.064 2.373.266 3.122-.17.749-.437 10.34-7.984 10.34-7.984l.556-.474.003-.003z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M312.922 216.965c-4.762 5.664-26.12 30.894-29.906 32.256-3.782 1.362 18.243 7.405 22.073 4.842 3.826-2.563 30.199-32.136 27.747-34.503-2.452-2.367-17.692-5.234-19.914-2.592v-.003z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.48"
                    d="M313.229 218.798c-4.13 4.912-23.201 28.536-26.481 29.718-3.28 1.182 15.471 5.351 18.793 3.129 3.321-2.222 26.508-28.877 24.382-30.931-2.127-2.055-14.767-4.204-16.694-1.913v-.003z"
                ></path>
                <path
                    fill="#87C9A6"
                    d="M306.561 239.686c-1.314 0-2.357-.43-2.86-1.179-.41-.613-.442-1.397-.085-2.269 1.564-3.84 5.328-5.825 8.361-6.087.203-.019.405-.026.598-.026 1.422 0 2.531.468 3.122 1.315.515.736.581 1.729.189 2.791-.85 2.307-3.659 4.257-7.517 5.218a7.465 7.465 0 01-1.808.237z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M312.575 230.299c1.365 0 2.423.439 2.979 1.239.487.699.547 1.612.171 2.633-.831 2.253-3.596 4.162-7.397 5.11a7.28 7.28 0 01-1.767.231c-1.254 0-2.247-.401-2.717-1.1-.38-.562-.402-1.289-.07-2.108 1.539-3.774 5.236-5.724 8.216-5.983.199-.015.395-.025.581-.025m0-.344c-.199 0-.401.009-.613.028-3.239.281-6.97 2.427-8.506 6.194-.885 2.175.591 3.682 3.106 3.682.569 0 1.191-.075 1.849-.24 3.403-.847 6.658-2.664 7.637-5.325.917-2.487-.622-4.336-3.476-4.336l.003-.003zM300.241 246.869c1.757.285 3.836-.632 4.639-2.044.803-1.413.029-2.791-1.728-3.075-1.757-.285-3.837.632-4.639 2.044-.803 1.413-.029 2.791 1.728 3.075zM316.465 227.667c1.681.044 3.526-.955 4.12-2.232.594-1.277-.291-2.348-1.972-2.392-1.681-.044-3.526.954-4.12 2.231-.594 1.277.29 2.348 1.972 2.393zM316.076 217.86s-5.524 7.692-7.082 8.561c-1.561.866-2.771.844-2.771.844l7.417-8.957 2.439-.445-.003-.003zM315.065 241.661c-2.193.613-3.255.582-4.118 1.413-.862.831-9.091 9.231-8.469 9.2.623-.032 2.386-.092 3.063-.632.676-.541 9.043-9.428 9.043-9.428l.481-.55v-.003z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M131.129 338.526c-5.223-3.255-12.88-10.338-17.168-10.338-5.422 0-12.567 10.29-20.331 11.355-6.238.857-20.173-.208-20.432 1.047-.26 1.254 10.051 15.555 13.98 15.211 4.641-.405 14.175.069 19.291-4.532 4.506-4.055 8.247-7.532 11.723-8.808 4.048-1.489 14.836-2.75 12.937-3.932v-.003z"
                ></path>
                <path
                    fill="#87C9A6"
                    d="M102.547 346.063c-.464 0-.938-.025-1.415-.072-1.552-.158-2.73-.816-3.075-1.713-.23-.598-.066-1.226.47-1.824 1.416-1.574 3.521-2.478 5.768-2.478.667 0 1.308.083 1.912.241 1.219.322 2.069.986 2.332 1.817.214.683.015 1.441-.566 2.133-1.027 1.22-2.951 1.893-5.423 1.893l-.003.003z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M104.295 340.153c.651 0 1.28.079 1.867.234 1.16.31 1.966.929 2.212 1.704.199.626.007 1.324-.534 1.969-.992 1.182-2.872 1.833-5.29 1.833-.458 0-.925-.025-1.396-.073-1.485-.152-2.61-.765-2.933-1.602-.205-.531-.053-1.1.436-1.647 1.384-1.536 3.442-2.421 5.641-2.421m0-.344c-2.057 0-4.307.768-5.897 2.534-1.497 1.663-.13 3.531 2.715 3.821.477.048.957.073 1.431.073 2.238 0 4.386-.562 5.556-1.953 1.418-1.688.587-3.581-1.852-4.229a7.69 7.69 0 00-1.956-.246h.003z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.4"
                    d="M113.171 330.094c-2.253.139-9.078 8.053-16.33 10.432-7.218 2.371-18.322 1.622-19.226 1.476-3.258-.518 6.769 12.197 10.532 11.868 1.233-.108 9.629.009 14.786-3.246 2.98-1.877 10.039-7.892 13.145-9.364 7.948-3.774 12.602-2.238 11.449-2.946-5.29-3.239-11.531-8.394-14.359-8.22h.003z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M89.879 348.854c1.327 1.037 3.466 1.223 4.778.421 1.311-.806 1.295-2.301-.032-3.338s-3.466-1.223-4.778-.42c-1.311.806-1.295 2.301.032 3.337zM111.613 338.738c1.375.802 3.359.815 4.434.028 1.074-.787.831-2.08-.544-2.882-1.374-.803-3.359-.816-4.433-.029-1.075.787-.831 2.08.543 2.883zM115.02 330.524c-2.02 1.438-6.15 4.933-7.05 4.826a5.387 5.387 0 01-1.612-.468l6.222-4.681 1.334-.076 1.106.399zM97.861 352.52c-.904-.518-1.672-1.128-2.294-1.074-.62.054-11.035.749-10.415 1.166.619.417 2.31 1.372 2.31 1.372l3.058-.222 4.905-.54 2.44-.698-.004-.004z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M253.629 300.186c-5.831-1.975-14.903-7.12-19.077-6.144-5.28 1.236-9.885 12.892-17.2 15.698-5.877 2.257-19.686 4.406-19.652 5.686.032 1.283 13.342 12.847 17.086 11.618 4.427-1.454 13.819-3.17 17.747-8.821 3.46-4.978 6.31-9.216 9.401-11.254 3.602-2.374 13.818-6.065 11.695-6.786v.003z"
                ></path>
                <path
                    fill="#87C9A6"
                    d="M225.318 314.361c-1.238 0-2.221-.411-2.629-1.103-.303-.512-.271-1.135.095-1.798 1.495-2.718 4.478-3.935 6.769-3.935h.196c1.26.038 2.237.487 2.686 1.236.366.616.344 1.4-.064 2.206-.856 1.694-3.191 2.939-6.24 3.334-.279.035-.55.054-.809.054l-.004.006z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M229.556 307.699h.189c1.201.035 2.127.455 2.541 1.153.335.563.31 1.29-.069 2.039-.831 1.64-3.116 2.854-6.112 3.239a6.125 6.125 0 01-.787.054c-1.175 0-2.101-.379-2.48-1.018-.272-.458-.237-1.021.098-1.627 1.46-2.655 4.376-3.843 6.617-3.843m0-.345c-2.484 0-5.439 1.324-6.921 4.023-.967 1.761.351 3.158 2.683 3.158.266 0 .544-.019.831-.057 2.702-.351 5.379-1.46 6.374-3.426.995-1.966-.243-3.619-2.768-3.695h-.199v-.003z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.4"
                    d="M234.22 296.078c-2.161.651-6.999 9.914-13.515 13.887-6.488 3.957-17.468 5.764-18.379 5.831-3.289.24 9.373 10.328 12.966 9.146 1.175-.385 9.375-2.193 13.654-6.536 2.471-2.509 7.969-9.974 10.659-12.12 6.876-5.49 11.758-5.06 10.475-5.483-5.887-1.944-13.142-5.538-15.857-4.722l-.003-.003z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M215.829 319.664c1.529.705 3.656.402 4.746-.682 1.09-1.085.737-2.535-.793-3.24-1.529-.705-3.656-.401-4.746.683-1.09 1.084-.736 2.534.793 3.239zM234.678 304.851c1.523.468 3.457.025 4.323-.986.866-1.011.335-2.212-1.188-2.68-1.523-.468-3.457-.025-4.323.986-.866 1.011-.335 2.212 1.188 2.68zM236.119 296.075c-1.637 1.861-4.86 6.21-5.76 6.308-.904.101-1.678-.089-1.678-.089l4.989-5.979 1.283-.376 1.169.136h-.003zM224.437 321.412c-.999-.3-1.887-.714-2.478-.522-.591.193-10.57 3.249-9.875 3.515.699.265 2.56.806 2.56.806l2.926-.917 4.652-1.646 2.215-1.236z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M122.307 265.643c2.746.012 7.533-.557 11.906-2.639 2.607-4.845 5.672-12.44 5.606-14.88-.022-.777-6.873 3.802-10.282 6.299a12.784 12.784 0 00-2.219 2.092c-.196.234-.385.474-.565.718-.225.297-.436.603-.642.916-.755 1.144-1.4 2.38-2.01 3.65-.36.74-.701 1.495-1.049 2.244-.082.174-.161.348-.24.522l-.502 1.078h-.003zM87.02 265.412c4.41 4.187 7.602 6.833 13.239 9.115 5.638 2.281 12.656 2.183 17.229-1.83 2.155-1.89 3.558-4.419 4.815-7.054a14.26 14.26 0 01-1.128-.048c-8.57-.727-13.445-4.63-20.685-13.599-1.068-1.325-15.332 10.208-14.776 11.826.165.481.629.762 1.305 1.593v-.003z"
                ></path>
                <path
                    fill="#87C9A6"
                    d="M110.213 271.173c-1.011 0-2.161-.256-3.324-.739-1.112-.462-1.839-1.207-1.896-1.947-.038-.487.221-.91.746-1.229a5.792 5.792 0 013.011-.825c1.144 0 2.282.309 3.198.875.831.509 1.312 1.188 1.321 1.858.006.55-.306 1.062-.888 1.445-.569.373-1.299.562-2.168.562z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M108.75 266.597c1.116 0 2.222.303 3.113.85.78.481 1.235 1.106 1.242 1.719.006.5-.275.949-.816 1.306-.543.354-1.241.534-2.079.534-.989 0-2.117-.25-3.261-.727-1.055-.439-1.744-1.132-1.798-1.808-.041-.531.341-.878.67-1.078a5.65 5.65 0 012.929-.799m0-.326a5.918 5.918 0 00-3.096.847c-1.492.907-.892 2.611 1.172 3.467 1.106.459 2.3.753 3.387.753.847 0 1.631-.177 2.26-.588 1.438-.942 1.242-2.544-.436-3.578-.904-.556-2.07-.901-3.287-.901zM97.02 265.94c1.192 1.245 3.331 1.747 4.775 1.125 1.444-.623 1.65-2.137.455-3.382-1.191-1.245-3.33-1.748-4.775-1.125-1.444.623-1.649 2.136-.454 3.382zM124.098 261.796c.149.079.319.152.503.209 1.359.414 3.093-.089 3.877-1.116.784-1.03.322-2.199-1.036-2.61a3.553 3.553 0 00-1.337-.13c-.755 1.144-1.4 2.38-2.01 3.65l.003-.003z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.44"
                    d="M99.403 255.346c-1.479.818-11.407 7.177-10.033 8.697 1.375 1.521 8.589 9.036 15.313 9.125 6.725.091 14.018-3.148 15.137-4.915 1.118-1.767 1.456-1.451.354-1.609-1.103-.158-5.164 1.309-10.874-2.3-5.713-3.61-9.897-8.998-9.897-8.998z"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M123.049 264.043c.556-.132 1.277-.3 2.149-.502 2.872-.67 6.446-1.504 7.22-1.71 1.1-.291 2.633-5.594 3.309-8.002-1.45.531-6.352 2.329-8.974 3.404.183-.244.369-.484.565-.718 3.198-1.277 8.377-3.16 8.668-3.261l.414-.149-.139.503c-1.122 4.01-2.427 8.321-3.726 8.666-.78.208-4.36 1.042-7.233 1.713-.932.218-1.839.429-2.493.584.079-.174.161-.347.24-.521v-.007z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M183.916 309.586c3.167-.114 8.185-3.104 7.783-4.179-.401-1.074-11.05-9.057-16.529-13.631-1.587 2.017-3.909 3.562-6.519 4.343-1.343.401-2.841.648-3.827 1.558 1.084-.126 2.025.61 2.797 1.293l7.147 6.324c1.429 1.264 3.024 2.348 4.677 3.375 1.15.714 2.696.98 4.471.913v.004z"
                ></path>
                <path
                    fill="#1E6E56"
                    d="M166.284 298.018c3.877 3.512 7.78 6.814 12.115 9.87-.297-1.027-.806-1.444-.663-2.569.199-1.596 1.406-2.061.742-2.854-1.011-1.21-4.816-1.542-5.925-1.615-1.814-.114-.496.37-6.272-2.832h.003z"
                ></path>
                <path
                    fill="#3EB58A"
                    d="M178.478 302.43c-3.71-3.483-7.609-6.434-11.41-9.652-1.716-1.45-3.54-3.698-5.796-4.342-2.85-.819-6.016.626-8.601 2.003-2.585 1.382-4.668 3.385-6.677 5.392-2.007 2.007-6.624 4.068-6.491 5.588.133 1.52 13.822 14.263 16.176 14.058 2.354-.206 6.412-6.147 10.292-8.745 3.877-2.598 8.014-5.184 12.51-4.299l-.003-.003z"
                ></path>
                <path
                    fill="#fff"
                    d="M173.106 297.753c-1.226.979-2.926 1.4-4.768 1.416-.168 0-.332 0-.499-.007-.098 0-.193-.006-.291-.009a16.959 16.959 0 01-3.514-.569c-3.539-.948-4.888-3.59-2.654-5.385 1.235-.993 3.416-2.472 3.823-2.102.559.505 7.906 6.656 7.906 6.656h-.003z"
                    opacity="0.18"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.3"
                    d="M177.593 306.194s3.066 1.669 5.12 1.65c1.507-.016 5.002-1.488 5.893-2.25s-12.883-11.994-13.531-11.476c-.958.768-3.334 2.506-3.334 2.506"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="2.18"
                    d="M173.106 297.753c-1.226.98-2.926 1.4-4.768 1.416-.168 0-.332 0-.499-.007-.098 0-.193-.006-.291-.009a16.959 16.959 0 01-3.514-.569c-3.539-.948-4.888-3.59-2.654-5.385 1.235-.993 3.447-1.704 3.823-2.102"
                ></path>
                <path
                    fill="#1B5B44"
                    d="M155.072 302.098c1.394 1.097 3.644 1.299 5.025.456 1.381-.844 1.371-2.415-.022-3.512-1.394-1.096-3.644-1.299-5.025-.455-1.381.844-1.371 2.415.022 3.511z"
                ></path>
                <path
                    stroke="#1B5B44"
                    strokeMiterlimit="10"
                    strokeWidth="1.3"
                    d="M163.225 289.403s-5.29.464-9.319 2.898c-3.694 2.231-11.391 8.467-10.516 9.096.875.629 11.36 11.229 12.924 10.167 1.564-1.062 7.192-6.574 12.002-9.096 2.613-1.371 8.519-1.542 8.519-1.542"
                ></path>
                <path
                    fill="#DDA66F"
                    d="M277.129 236.933c1.286-.19 8.21-1.934 14.467-1.748 4.613.139 8.383 2.314 13.29 2.529 8.501.376 19.172 2.171 25.77 3.034 3.726.486 2.202-28.015 1.235-28.555-10.134-5.686-40.592 3.581-42.242 3.524-1.649-.057-7.394 3.454-7.394 3.454s-8.946 18.321-5.119 17.762h-.007z"
                ></path>
                <path
                    fill="#fff"
                    d="M392.373 498.947c.376-4.001.057-14.136.057-14.136l-10.617-.354.619 15.22 7.031 4.82 2.91-5.546v-.004z"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M393.198 497.263c1.122-.221.616.818.964 2.516.793 3.877 14.77 4.525 16.865 4.952 1.08.221 2.619 5.938-2.797 5.705-4.683-.2-19.68-.787-24.803-1.04a2.357 2.357 0 01-2.196-1.865c-.78-3.748-.303-9.497 1.132-9.567 2.186-.606 6.161.225 10.838-.701h-.003z"
                ></path>
                <path
                    fill="#fff"
                    d="M288.003 484.653c3.289-2.308 10.8-9.122 10.8-9.122l-6.61-8.315-11.186 10.335.887 8.48 6.112-1.378h-.003z"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M289.82 484.191c.897.711-.225 1.002-1.29 2.364-2.439 3.119 6.128 14.181 7.161 16.052.534.967-2.825 5.844-6.156 1.568-2.882-3.695-12.156-15.499-15.285-19.564a2.358 2.358 0 01-.003-2.882c2.348-3.021 7.034-6.387 8.017-5.338 1.88 1.274 3.824 4.839 7.559 7.8h-.003z"
                ></path>
                <path
                    fill="#3B3B4C"
                    d="M396.156 491.754c.218 2.257-17.541 1.182-18.044-.559-.502-1.742 9.392-49.465 4.838-62.138-.749-2.076-31.252-42.047-31.252-42.047-4.038 15.98-9.666 34.484-16.744 51.168-10.827 21.608-36.77 40.239-38.966 42.616-2.196 2.376-14.043-9.131-12.014-10.935 2.032-1.802 30.187-29.604 33.597-38.621 2.866-7.579 3.668-42.809 6.237-67.321.477-4.564 1.053-8.77 1.751-12.383.215-1.112 7.679-2.44 15.563-3.533 8.965-1.245 18.47-2.19 18.47-2.19l6.705-1.419c4.329 14.06 35.999 70.715 37.114 81.691 1.116 10.976-7.473 63.421-7.255 65.674v-.003z"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M361.045 399.244l-9.41-12.17c1.119-4.808 1.333-7.102 1.735-8.154 1.829 1.611 7.672 20.324 7.672 20.324h.003z"
                    opacity="0.57"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M348.794 350.428c-5.097 9.478-17.73 11.706-24.986 13.489.477-4.564 1.053-8.77 1.751-12.383.215-1.112 7.679-2.44 15.563-3.533l7.672 2.427z"
                    opacity="0.6"
                ></path>
                <path
                    fill="#32323F"
                    d="M236.223 508.628c0 1.558 49.789 3.369 107.152 3.369s103.868-1.264 103.868-2.822c0-1.559-46.505-2.823-103.868-2.823-57.363 0-107.152.718-107.152 2.276z"
                    opacity="0.14"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M349.35 378.291l.007.022c.082-.051.117-.073.126-.082-.022 0-.133.056-.133.056v.004z"
                    opacity="0.46"
                ></path>
                <path
                    fill="#EFAEA6"
                    d="M430.574 313.903h-.695s-1.495.275-3.334.783c-2.016.557-4.449 1.397-5.786 2.478-1.463 1.179-4.038 1.483-6.032 4.137-1.492 1.988-8.197-4.345-8.197-4.345l5.274-10.55c3.675-1.163 10.772-.613 14.248-.139 3.862.525 7.888 2.889 7.888 2.889l-3.369 4.75.003-.003z"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M426.545 314.686c-2.016.557-4.449 1.397-5.786 2.478-.417-6.615 4.247-9.38 6.064-8.426 1.132.598-.028 3.439-.028 3.439l-.25 2.509z"
                    opacity="0.14"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M412.142 304.241s-12.03-7.645-24.12-4.576c-11.692 2.967-26.847 12.092-29.577 12.465-2.727.373-39.073 8.176-41.718 10.537-2.645 2.36 23.147-1.609 25.381-.986 2.231.619 62.377-17.487 62.377-17.487s6.791 1.04 7.66.047h-.003z"
                    opacity="0.21"
                ></path>
                <path
                    fill="#fff"
                    d="M412.96 305.287c2.31 1.972-2.085 8.783-.164 11.624 1.166 1.726 5.243-.442 6.57-.243.818.123 1.665.174 1.523 1.504-2.888 27.06-56.807 42.379-57.903 46.399-.895 3.29-15.791-5.856-20.313-15.439-6.2-13.138 5.865-21.608 8.324-23.033 2.455-1.425 45.547-23.969 45.547-23.969s8.286 1.561 16.416 3.157z"
                ></path>
                <path
                    fill="#0267A4"
                    d="M420.889 318.172s-6.484 2.876-12.308 8.929c-7.698 7.996-17.848 7.797-27.464 17.095-12.437 12.028-23.589 14.841-23.879 20.723-.171 3.426 8.977 5.957 15.066 6.017 3.944.038 11.42-10.309 15.159-14.064 1.456-1.463 3.874-5.404 10.039-9.206 1.792-1.106 7.944-4.924 10.343-6.843 3.223-2.575 9.697-9.794 10.955-12.995 1.081-2.744 3.005-8.941 2.086-9.659l.003.003zM413.093 305.316c-2.395 3.941-13.588 12.193-24.837 19.873a596.492 596.492 0 01-6.386 4.301 362.278 362.278 0 01-6.693 4.355c-4.977 3.145-10.368 6.302-14.261 7.794-10.396 3.982-7.328 12.032-15.797 13.448-7.625 1.273-21.301.085-23.308-6.84-2.389-8.248 22.186-21.08 32.039-26.146 2.168-1.113 3.972-2.165 5.138-3.129a167.1 167.1 0 003.132-2.652c.787-.682 1.475-1.289 2.142-1.871 1.678-1.472 3.22-2.797 5.818-4.772 1.27-.961 4.158-2.591 6.231-3.694 5.293-2.82 13.525-5.509 17.984-5.522 11.401-.038 18.805 4.852 18.805 4.852l-.007.003z"
                ></path>
                <path
                    fill="#EFAEA6"
                    d="M440.064 319.626c-3.047.974-8.248 5.771-8.703 3.622-.455-2.152-6.437 2.064-7.002-.208-1.369-5.503.682-12.661 3.137-13.973 2.456-1.311 9.831-2.591 9.831-2.591l2.737 13.15z"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M435.851 314.601c.177 1.792-.215 1.941.626 3.034.841 1.094 2.032-1.4 2.891.8.86 2.199 1.536 4.677 4.137 4.333 3.763-.496 2.468-3.717 1.596-5.692-.563-1.271 2.193-2.244 1.497-4.88-.543-2.067-1.681-1.552-2.246-3.375-.342-1.1.992-3.708-.705-5.161-3.147-2.693-5.574.708-8.769-1.4-3.726-2.459-8.658 6.336-7.691 7.006 1.131.781 3.975-1.4 4.932-.569 1.441 1.255.228 1.729-.663 3.259-.951 1.634-.49 4.146 1.564 1.997 2.054-2.149 2.67-.973 2.828.651l.003-.003z"
                ></path>
                <path
                    fill="#1F1F39"
                    d="M410.309 315.821c-.483 1.106.155 1.558-.205 2.468-.36.911-4.295 10.139-4.778 14.004-.341 2.712-1.068 11.154-1.068 11.154l3.492-2.715 2.474-15.534 1.782-8.912-1.697-.462v-.003zM336.028 331.993l-6.418 4.605s35.995-6.605 46.875-15.815c10.883-9.21-40.457 11.21-40.457 11.21z"
                    opacity="0.35"
                ></path>
                <path
                    fill="#EEADA5"
                    d="M319.261 324.041c-8.693 2.462-5.58-3.764-6.8-3.938-2.035-.29-2.525 3.003-2.945 3.992-1.707 4.011-9.632-.992-10.406.819-1.308 3.056 2.73 8.59 8.409 7.992 1.349-.142 2.765-1.485 5.672-1.599 3.637-.145 6.968.383 6.686-.622-.281-1.002.228-6.881-.613-6.644h-.003z"
                ></path>
                <path
                    fill="#0267A4"
                    d="M381.797 303.246c-5.609 2.67-19.532 10.767-24.97 11.614-5.53.863-37.677 6.735-39.977 7.56-2.3.825-2.676 11.428-1.267 12.5 1.406 1.071 40.002-5.544 43.241-6.293 14.435-3.343 38.144-11.172 41.339-13.748 3.194-2.572 6.857-4.146 4.973-9.266-1.491-4.052-17.724-5.038-23.333-2.367h-.006z"
                ></path>
                <path
                    fill="#0267A4"
                    d="M375.528 308.059c-2.203.657-3.574 1.722-5.486 3.423-1.912 1.7-.332 2.209.107 2.49.439.285 5.366.85 5.764.335.398-.515-.382-6.251-.382-6.251l-.003.003zM356.128 321.756c-2.208 1.198-3.286.889-4.123 2.652-.838 1.764 7.394 1.593 7.394 1.593l-1.024-3.609-2.247-.639v.003z"
                ></path>
                <path
                    fill="#F4AD00"
                    d="M410.47 315.884c.32-1.062 1.255-1.728 2.892-1.4 1.422.288 1.842 2.443.553 3.553-1.081.926-2.73 7.973-3.618 17.543-.534 5.743 2.505 12.541 1.668 19.33-2.253 18.28 4.775 33.564 4.446 36.218-.328 2.655-.951 5.158-2.123 4.555-1.176-.607-4.468-2.996-4.737-5.218-.269-2.219-4.93-16.144-2.869-34.949.742-6.776-.692-14.585-.285-20.236.493-6.823 4.905-16.633 4.923-17.474.026-1.043-1.162-.891-.85-1.928v.006z"
                ></path>
                <path
                    fill="#fff"
                    d="M446.586 295.389c-.165.733.796 1.166 1.235.559 2.177-2.993 3.783-6.991 2.155-7.768-1.39-.664-2.695 4.08-3.39 7.209zM437.934 294.216c.192.705 1.204.67 1.349-.047 1.014-5.038.604-11.602-1.965-11.533-2.219.06-.465 7.598.619 11.583l-.003-.003zM430.824 294.883c.448.616 1.419.161 1.232-.575-.78-3.12-2.458-6.28-3.887-5.667-1.213.522.974 3.922 2.655 6.242z"
                ></path>
                <path
                    fill="#3B3B4C"
                    d="M313.459 216.507c-4.578.477-13.208 2.475-14.744-4.82-1.88-24.029-18.843-45.049 13.999-42.606 20.966 3.897 22.919 46.149.745 47.426zm-5.248-10.24c15.581 3.05 12.513-29.282-1.397-26.75-7.477-3.167 2.307 24.566 1.397 26.75z"
                ></path>
                <path
                    fill="#3B3B4C"
                    d="M331.708 172.586c-3.273-11.678 18.736-8.9 23.409-11.419 8.109 12.449-6.885 11.337-12.899 12.87l1.264 6.902 13.124-2.402c2.161 2.902 3.732 9.921-.547 10.534-10.845 2.977-11.809-1.887-9.306 9.756 5.773-.006 19.709-8.201 16.726 6.261-.525 2.683-17.589 3.948-19.343 4.804-9.988 2.92-9.619-31.75-12.428-37.312v.006zM384.556 203.502c-4.636.461-13.452 2.651-15.039-4.766.522-3.331-9.676-37.995-4.525-38.912 5.871-3.6 17.023-5.872 22.844-2.645 6.361 2.983 8.563 15.714 1.927 19.44 15.753 4.611 9.417 26.949-5.211 26.883h.004zm-9.019-27.885c12.725.174 9.919-13.916-1.602-8.761l1.602 8.761zm3.312 18.113c8.427 2.695 14.387-10.73 3.125-9.934-7.309.933-4.156.85-3.125 9.934zM398.194 164.031c-2.288-3.066-3.893-10.417.626-11.084 3.074 1.094 28.803-9.895 27.488.341 1.988 7.108-5.609 6.347-9.157 7.276l6.272 34.294c-2.86 1.179-8.099 3.587-10.292-.572-.717-.149-5.814-30.454-6.187-31.854l-8.75 1.599z"
                ></path>
            </g>
            <circle cx="68" cy="506" r="17" fill="#FEF7ED"></circle>
            <defs>
                <clipPath id="clip0_27_25">
                    <path fill="#fff" d="M0 0H486V512H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default MainImg;
