import React from "react";

function DebtReliefStrategy() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="68"
            fill="none"
            viewBox="0 0 68 68"
        >
            <rect width="68" height="68" fill="#C0BFFF" rx="13"></rect>
            <path
                fill="#000"
                d="M22.714 29.979v10.327a3.952 3.952 0 001.152 2.786 3.927 3.927 0 002.777 1.155h4.389l-3.373 3.384a.788.788 0 101.11 1.115l4.715-4.73a.789.789 0 000-1.114l-4.714-4.73a.784.784 0 00-1.332.56.79.79 0 00.22.555l3.374 3.384h-4.39c-.624 0-1.223-.25-1.665-.694a2.372 2.372 0 01-.691-1.67V29.978a5.496 5.496 0 003.498-2 5.53 5.53 0 00-.537-7.5 5.49 5.49 0 00-7.494 0 5.53 5.53 0 00-.537 7.5 5.496 5.496 0 003.498 2zm.786-9.38a3.92 3.92 0 012.183.665 3.953 3.953 0 01.595 6.064 3.925 3.925 0 01-4.281.854 3.932 3.932 0 01-1.764-1.451 3.95 3.95 0 01.49-4.976A3.927 3.927 0 0123.5 20.6zm22.786 17.422V27.694a3.952 3.952 0 00-1.152-2.786 3.927 3.927 0 00-2.777-1.155h-4.389l3.373-3.384a.79.79 0 00-.558-1.336.784.784 0 00-.553.221l-4.714 4.73a.788.788 0 000 1.114l4.714 4.73a.784.784 0 001.332-.56.79.79 0 00-.22-.555l-3.374-3.384h4.39c.624 0 1.223.25 1.665.694.442.443.69 1.044.691 1.67v10.328a5.496 5.496 0 00-3.498 2 5.53 5.53 0 00.537 7.5 5.49 5.49 0 007.494 0 5.53 5.53 0 00.537-7.5 5.496 5.496 0 00-3.498-2zm-.786 9.38a3.92 3.92 0 01-2.183-.665 3.953 3.953 0 01-.595-6.064 3.925 3.925 0 014.281-.854 3.932 3.932 0 011.764 1.451 3.95 3.95 0 01-.49 4.976A3.927 3.927 0 0145.5 47.4z"
            ></path>
        </svg>
    );
}

export default DebtReliefStrategy;
