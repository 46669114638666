import React from "react";

function EvaluateDebts() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="68"
            fill="none"
            viewBox="0 0 68 68"
        >
            <rect width="68" height="68" fill="#DBE8F9" rx="13"></rect>
            <path
                fill="#000"
                d="M19.728 49.14a.463.463 0 01-.447-.575l1.857-7.298c-1.553-1.048-2.785-2.263-3.665-3.615C16.496 36.15 16 34.55 16 32.892c0-3.213 1.874-6.22 5.277-8.468C24.622 22.216 29.06 21 33.773 21c4.714 0 9.151 1.216 12.495 3.424 3.403 2.248 5.277 5.255 5.277 8.469 0 3.213-1.874 6.22-5.277 8.468-3.345 2.21-7.782 3.425-12.495 3.425-2.394 0-4.714-.311-6.9-.927l-6.865 5.188a.464.464 0 01-.279.094zm14.044-27.217c-9.29 0-16.848 4.921-16.848 10.97 0 2.937 1.773 5.7 4.994 7.782.166.107.245.31.196.502l-1.613 6.34 6-4.534a.464.464 0 01.408-.075c2.167.633 4.476.954 6.864.954 9.29 0 16.848-4.92 16.848-10.97 0-6.048-7.558-10.969-16.849-10.969z"
            ></path>
            <path
                fill="#000"
                d="M26.801 36.007a.463.463 0 01-.215-.053l-2.01-1.057-2.01 1.057a.46.46 0 01-.67-.487l.384-2.238-1.626-1.585a.463.463 0 01.256-.788l2.247-.327 1.005-2.036a.462.462 0 01.828 0l1.006 2.036 2.247.326a.462.462 0 01.256.789l-1.627 1.585.384 2.238a.462.462 0 01-.455.54zm-2.225-2.093a.46.46 0 01.215.053l1.397.734-.267-1.555a.464.464 0 01.133-.41l1.13-1.1-1.562-.228a.463.463 0 01-.347-.253l-.699-1.415-.698 1.415a.463.463 0 01-.348.253l-1.561.227 1.13 1.102c.108.106.158.259.132.409l-.267 1.555 1.397-.734a.46.46 0 01.215-.053zM36.115 36.007a.464.464 0 01-.215-.053l-2.01-1.057-2.01 1.057a.46.46 0 01-.67-.487l.384-2.238-1.626-1.585a.462.462 0 01.256-.788l2.247-.327 1.005-2.036a.463.463 0 01.828 0l1.005 2.036 2.247.326a.462.462 0 01.256.789l-1.626 1.585.383 2.238a.461.461 0 01-.454.54zm-2.224-2.093a.46.46 0 01.214.053l1.397.734-.268-1.555a.463.463 0 01.134-.41l1.129-1.1-1.56-.228a.461.461 0 01-.348-.253l-.698-1.415-.7 1.415a.46.46 0 01-.347.253l-1.561.228 1.13 1.101c.108.106.158.26.133.41l-.268 1.554 1.397-.734a.46.46 0 01.216-.053zM45.43 36.007a.465.465 0 01-.216-.053l-2.01-1.057-2.01 1.057a.461.461 0 01-.67-.487l.385-2.238-1.627-1.585a.463.463 0 01.256-.788l2.247-.327 1.005-2.036a.462.462 0 01.829 0l1.005 2.036 2.247.326a.462.462 0 01.256.789L45.5 33.229l.384 2.238a.464.464 0 01-.454.54zm-2.226-2.093c.075 0 .149.017.215.053l1.397.734-.267-1.555a.462.462 0 01.133-.41l1.13-1.1-1.562-.228a.46.46 0 01-.347-.253l-.7-1.415-.698 1.415a.46.46 0 01-.347.253l-1.562.227 1.13 1.102c.11.106.159.259.133.409l-.267 1.555 1.397-.734a.464.464 0 01.215-.053z"
            ></path>
        </svg>
    );
}

export default EvaluateDebts;
