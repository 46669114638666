import React, { ReactElement } from "react";
import MainImg from "./icons/mainImg";
import SharedButton from "../sharedButton";
export default function WhySeekDebtRelief(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row  lg:gap-20 gap-8 lg:mt-40 mt-24 ">
            <div className="max-w-[600px] mx-auto lg:mx-0 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#0071AC] ">
                    Why Seek Debt Relief?
                </h2>

                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    Debt can be incredibly overwhelming and might make you feel
                    stuck in an endless cycle. But here's the truth: With a
                    reliable and trustworthy debt relief company, you can
                    finally discover the financial freedom you've been longing
                    for.
                </p>
                <p className="mt-4 font-light lg:text-lg  text-base lg:leading-8 ">
                    Individuals, businesses, and even nations can seek out debt
                    relief programs. Whether it's debt consolidation,
                    settlement, management plans, bankruptcy, student loan
                    forgiveness, or mortgage relief, a savvy provider can
                    expertly guide you toward the path that suits you best.
                    They'll offer the relief and freedom from debt that you
                    deserve.
                </p>

                <div className="mt-12 hidden sm:block">
                    <SharedButton />
                </div>
            </div>
            <div className="w-[221px] h-[233px] md:w-[486px] md:min-w-[486px] md:h-[512px]  relative  mx-auto lg:mx-0 ">
                <MainImg />
            </div>
            <div className="mt-12 text-center sm:hidden">
                <SharedButton />
            </div>
        </section>
    );
}
