import React from "react";

function ProtectYourDebt() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 78 78"
        >
            <path
                fill="#2184C3"
                d="M39.293 46.677c.67 0 1.212-.543 1.212-1.212v-.987a3.03 3.03 0 001.119-.796c.529-.61.816-1.393.81-2.2a3.107 3.107 0 00-3.225-3.198h-.83a.752.752 0 01-.795-.82.845.845 0 01.19-.561.764.764 0 01.604-.217h2.327a1.213 1.213 0 000-2.425h-.2v-.92a1.213 1.213 0 00-2.424 0v.935a3.097 3.097 0 00-2.922 3.188 3.167 3.167 0 003.22 3.245h.83a.712.712 0 01.8.773.91.91 0 01-.205.597.745.745 0 01-.596.227h-2.326a1.213 1.213 0 000 2.425h1.199v.733c0 .67.542 1.213 1.212 1.213zM43.207 48.036c.337.145.66.288.96.422.663.296 1.255.56 1.766.725a12.267 12.267 0 003.171-3.429 14.672 14.672 0 01-1.865-1.594 9.776 9.776 0 01-4.032 3.876z"
            ></path>
            <path
                fill="#2184C3"
                d="M31.917 46.23a9.694 9.694 0 0111.457-15.374c.174-.8.416-1.582.722-2.34A12.098 12.098 0 0029.11 46.663c.93-.18 1.875-.328 2.807-.432zM76.392 49.671c-.63-.655-2.046-1.646-4.452-.93-1.444.43-6.04 3.687-9.841 6.499a7.61 7.61 0 01.314 2.792c4.438-3.306 9.16-6.653 10.218-6.967a1.968 1.968 0 012.013.287c.551.708.689 1.655.36 2.49-.86 2.533-16.957 15.864-23.593 19.541-5.788 3.208-9.599 1.392-12.38.066a67.384 67.384 0 00-14.784-4.817v.474a7.248 7.248 0 01-.27 1.936 66.274 66.274 0 0114.01 4.596 16.15 16.15 0 007.208 1.958 15.099 15.099 0 007.392-2.092c5.81-3.22 23.408-17.046 24.713-20.88a4.907 4.907 0 00-.908-4.953z"
            ></path>
            <path
                fill="#2184C3"
                d="M35.511 50.963c2.324.23 4.586.881 6.676 1.92a16.27 16.27 0 003.142 1.158c1.521.26 3.064.386 4.609.375 2.864.077 6.11.165 7.16 1.518.48.673.62 1.533.374 2.323-.29.92-1.106 1.614-2.362 2.005-3.225 1.004-12.044 1.931-15.693 1.431a1.212 1.212 0 10-.328 2.403c.96.115 1.927.167 2.895.156 4.52 0 11.032-.798 13.847-1.675 2.02-.63 3.423-1.905 3.954-3.593a5.1 5.1 0 00-.77-4.535c-1.753-2.26-5.443-2.36-9.011-2.457a24.803 24.803 0 01-4.156-.319 14.334 14.334 0 01-2.67-1.003 21.663 21.663 0 00-7.445-2.122c-3.672-.337-8.845 1.009-12.044 2.002.313.757.495 1.56.538 2.377 3.004-.942 7.97-2.27 11.285-1.964zM16.974 48.495H1.212a1.212 1.212 0 100 2.424h9.7v20.61h-9.7a1.213 1.213 0 000 2.425h15.762a4.854 4.854 0 004.85-4.849V53.343a4.854 4.854 0 00-4.85-4.849v.001zm2.425 20.61a2.427 2.427 0 01-2.425 2.424h-3.637V50.92h3.637a2.428 2.428 0 012.425 2.425v15.761zM57.589 46.07a12.124 12.124 0 10-12.124-12.124A12.14 12.14 0 0057.589 46.07zm0-21.822a9.7 9.7 0 11-9.7 9.699 9.71 9.71 0 019.7-9.7z"
            ></path>
            <path
                fill="#2184C3"
                d="M57.17 35.254H58a.71.71 0 01.8.773.907.907 0 01-.204.597.745.745 0 01-.595.226h-2.327a1.213 1.213 0 000 2.425h1.199v.734a1.213 1.213 0 002.425 0v-.986a3.031 3.031 0 001.118-.797c.529-.61.817-1.392.81-2.2A3.107 3.107 0 0058 32.83h-.83a.752.752 0 01-.794-.82.847.847 0 01.189-.562.766.766 0 01.604-.216h2.327a1.212 1.212 0 100-2.425h-.2v-.921a1.213 1.213 0 00-2.424 0v.935a3.1 3.1 0 00-2.921 3.188 3.166 3.166 0 003.218 3.246zM57.589 19.398c.67 0 1.212-.543 1.212-1.213V7.274a1.212 1.212 0 10-2.424 0v10.911c0 .67.542 1.213 1.212 1.213zM57.589 3.637c.67 0 1.212-.543 1.212-1.212V1.212a1.212 1.212 0 10-2.424 0v1.213c0 .67.542 1.212 1.212 1.212zM66.076 21.217c.67 0 1.213-.543 1.213-1.212V9.093a1.213 1.213 0 00-2.425 0v10.912c0 .67.543 1.212 1.212 1.212zM66.076 5.455c.67 0 1.213-.542 1.213-1.212V3.031a1.213 1.213 0 00-2.425 0v1.212c0 .67.543 1.212 1.212 1.212zM49.102 21.217c.67 0 1.212-.543 1.212-1.212V9.093a1.213 1.213 0 00-2.425 0v10.912c0 .67.543 1.212 1.213 1.212zM49.102 5.455c.67 0 1.212-.542 1.212-1.212V3.031a1.213 1.213 0 00-2.425 0v1.212c0 .67.543 1.212 1.213 1.212z"
            ></path>
        </svg>
    );
}

export default ProtectYourDebt;
