import React from "react";

function SteerClearBankruptcy() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="58"
            height="60"
            fill="none"
            viewBox="0 0 77 78"
        >
            <path
                fill="#2184C3"
                d="M75.07 75.566h-4.513V34.153a6.13 6.13 0 00-.615-2.669l-1.458-2.996a1.014 1.014 0 00-1.016-.566l-4.01.406-2.17-8.14a1.015 1.015 0 00-1.112-.744l-7.099.91-2.754-3.55A12.903 12.903 0 0027.039 6.136H6.5c-.56 0-1.015.456-1.015 1.016v68.412H.974a1.016 1.016 0 000 2.031h74.095a1.016 1.016 0 000-2.03zM38.021 2.032a10.873 10.873 0 11-10.875 10.873A10.885 10.885 0 0138.022 2.032zm-8.048 73.534v-9.53h16.095v9.53H29.974zm18.126 0V65.02a1.014 1.014 0 00-1.014-1.014H28.959c-.56 0-1.015.453-1.016 1.014v10.545l-20.427.001V8.168h18.501A12.904 12.904 0 0049.413 18.95l1.782 2.297a.927.927 0 000 .134v6.944c0 .269.107.526.296.717l3.472 3.472-4.509 4.508a1.015 1.015 0 001.434 1.437l4.51-4.509 3.446 3.447a1.016 1.016 0 001.436-1.435l-4.157-4.172-3.892-3.892v-5.515l6.333-.813 2.155 8.117c.129.482.588.8 1.085.748l4.166-.42 1.147 2.358c.269.555.41 1.164.41 1.78v5.84h-4.239a1.016 1.016 0 000 2.03h4.239v33.544L48.1 75.566z"
            ></path>
            <path
                fill="#2184C3"
                d="M23.837 29.213h-8.1c-.56 0-1.015.454-1.016 1.015v8.1c0 .56.455 1.016 1.016 1.016h8.1a1.016 1.016 0 001.014-1.017v-8.099c0-.56-.454-1.015-1.014-1.015zm-1.017 8.1h-6.067v-6.069h6.067v6.069zM42.072 29.213h-8.1c-.56 0-1.014.454-1.016 1.015v8.1c0 .56.455 1.016 1.017 1.016h8.1a1.016 1.016 0 001.014-1.017v-8.099a1.014 1.014 0 00-1.015-1.015zm-1.016 8.1h-6.069v-6.069h6.068v6.069zM23.837 46.971h-8.1c-.561 0-1.016.455-1.016 1.017v8.1c.001.56.456 1.014 1.016 1.014h8.1a1.014 1.014 0 001.014-1.015v-8.1c0-.56-.453-1.014-1.014-1.016zm-1.017 8.1h-6.067v-6.069h6.067v6.069zM42.072 46.971h-8.1c-.56 0-1.016.455-1.016 1.017v8.1c.002.56.456 1.014 1.017 1.014h8.1a1.014 1.014 0 001.014-1.015v-8.1c0-.56-.454-1.014-1.015-1.016zm-1.016 8.1h-6.069v-6.069h6.068v6.069zM60.306 46.971h-8.09a1.016 1.016 0 00-1.015 1.017v8.1c0 .56.454 1.014 1.014 1.014h8.1a1.014 1.014 0 001.014-1.015v-8.1a1.017 1.017 0 00-1.023-1.016zm-1.016 8.1h-6.07v-6.069h6.069v6.069zM53.486 8.934a1.017 1.017 0 001.144.866 1.012 1.012 0 00.876-1.145l-.438-3.173a1.014 1.014 0 10-2.012.277l.43 3.175zM61.743 15.142a1.012 1.012 0 001.391-.35l2.026-3.393a1.015 1.015 0 10-1.743-1.04L61.39 13.74a1.015 1.015 0 00.352 1.402zM69.542 23.271c.324 0 .445 0 3.587-1.897a1.016 1.016 0 10-1.05-1.739c-1.14.689-2.514 1.493-2.841 1.651a1.016 1.016 0 00.303 1.985h.001zM38.093 17.983h-.044c-1.88-.05-2.248-1.078-2.278-1.17v-.001a1.017 1.017 0 00-1.974.487c.025.096.563 2.13 3.209 2.613v1.354a1.015 1.015 0 002.029 0v-1.371c1.535-.388 2.856-1.676 3.167-3.229.156-.777.34-3.44-3.817-5.042-1.833-.701-2.763-1.707-2.556-2.748.186-.923 1.205-1.752 2.164-1.715 1.862.05 2.241 1.051 2.278 1.193a1.017 1.017 0 001.966-.51c-.025-.097-.56-2.128-3.207-2.613V3.875a1.015 1.015 0 00-2.029 0v1.371c-1.535.388-2.858 1.676-3.168 3.23-.156.776-.34 3.44 3.817 5.041 1.833.701 2.764 1.709 2.556 2.75-.175.91-1.178 1.716-2.114 1.716z"
            ></path>
        </svg>
    );
}

export default SteerClearBankruptcy;
