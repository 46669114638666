import React, { ReactElement } from "react";
import QuickAndSimple from "./icons/quickAndSimple";
import EvaluateDebts from "./icons/evaluateDebts";
import GetExpert from "./icons/getExpert";
import SignAgreements from "./icons/signAgreements";
import GainFinancial from "./icons/gainFinancial";
import SharedButton from "../sharedButton";
import DebtReliefStrategy from "./icons/debtReliefStrategy";
const HowToGetDebtReliefList = [
    {
        description: "Fill out our quick and simple form",
        icon: <QuickAndSimple />,
        color: "#FFEEDC",
    },
    {
        description: "Evaluate your debts with one of our professionals",
        icon: <EvaluateDebts />,
        color: "#DBE8F9",
    },
    {
        description:
            "Get expert advice on which plans and programs would be best for your unique situation",
        color: "#FEF7ED",
        icon: <GetExpert />,
    },
    {
        description:
            "Make sure to read and sign agreements carefully so you fully understand the terms before committing to a debt relief plan",
        color: "#DAF1EB",
        icon: <SignAgreements />,
    },
    {
        description: "Implement your unique debt relief strategy",
        color: "#C0BFFF",
        icon: <DebtReliefStrategy />,
    },
    {
        description: "Gain financial freedom and secure your future",
        color: "#FF929F",
        icon: <GainFinancial />,
    },
];

export default function WhatNext(): ReactElement {
    return (
        <section className=" mt-24 lg:mt-32">
            <h2 className="text-2xl  lg:text-3xl font-bold text-[#0071AC] text-center sm:text-left">
                So, What Next?
            </h2>
            <h3 className="mt-[5px] lg:text-xl lg:leading-8 font-semibold text-lg text-center sm:text-left max-w-[343px] sm:max-w-full mx-auto sm:mx-0">
                We did the work for you, carefully evaluating debt relief
                companies to find you the most reliable, trustworthy, and
                accredited providers out there. What you have to do next is
                simple:
            </h3>
            <div className="flex flex-wrap mt-12 gap-12">
                {HowToGetDebtReliefList.map((point, index) => (
                    <div key={index} className=" mx-auto sm:mx-0">
                        <span className="flex justify-center sm:block">
                            {point.icon}
                        </span>
                        <p className="w-[300px] mt-5 text-center sm:text-left">
                            {point.description}
                        </p>
                        <span
                            style={{ backgroundColor: point.color }}
                            className="w-[162px] h-[2px] block mt-8 sm:hidden mx-auto"
                        ></span>
                    </div>
                ))}
            </div>
            <div className="mt-12 text-center sm:text-left">
                <SharedButton />
            </div>
        </section>
    );
}
