import React, { ReactElement } from "react";
import FeatureList from "@components/shared/featureList";
import EscapeTheGrip from "./icons/escapeTheGrip";
import SteerClearBankruptcy from "./icons/steerClearBankruptcy";
import ProtectYourDebt from "./icons/protectYourDebt";
import LegalActions from "./icons/legalActions";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
const BenefitsDebtSettlementList = [
    {
        description: "Break free from the clutches of overwhelming debt!",
        icon: <EscapeTheGrip />,
    },
    {
        description: "Avoid bankruptcy proceedings like the plague!",
        icon: <SteerClearBankruptcy />,
    },
    {
        description:
            "Ensure your debt is shielded from collection or charge-off",
        icon: <ProtectYourDebt />,
    },
    {
        description: "Guard yourself against legal actions stemming from debt",
        icon: <LegalActions />,
    },
];

export default function DebtFreeLife(): ReactElement {
    return (
        <section className="lg:mt-[140px] lg:mb-48 mb-24">
            <FeatureList
                featureList={BenefitsDebtSettlementList}
                mainTitle="Discover the Relief of a Debt-Free Life:"
                colors={{
                    mainTitleColor: "#2184C3",
                }}
                classes={{
                    mainTitleClasses:
                        "text-2xl  lg:text-3xl font-bold text-center",

                    innerSectionClasses:
                        "flex   flex-wrap   gap-[24px]  sm:mt-[40px] mt-[20px] justify-center",
                    oneBoxClasses: `w-full h-[205px] sm:w-[269px] sm:h-[192px] ${styles["oneBoxStyle"]} px-[15px]`,
                    iconClasses: " children:mx-auto block  mt-[33px]",
                    descriptionClasses: "font-light     text-center text-lg",
                }}
            />
            <div className="text-center mt-24">
                <SharedButton />
            </div>
        </section>
    );
}
