import React from "react";

function QuickAndSimple() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="68"
            height="68"
            fill="none"
            viewBox="0 0 68 68"
        >
            <rect width="68" height="68" fill="#FFEEDC" rx="13"></rect>
            <path
                fill="#000"
                d="M44.577 19.512h-5.06v-1.989a.537.537 0 00-.524-.523H28.106a.537.537 0 00-.523.523v1.954h-5.06a.537.537 0 00-.523.524v28.927c0 .28.244.524.523.524h22.054c.279 0 .523-.245.523-.524V20.036a.537.537 0 00-.523-.524zM28.63 18.047h9.875v3.943H28.63v-3.943zm15.423 30.358H23.047V20.559h4.501v1.954c0 .28.244.524.524.524h10.921c.28 0 .524-.245.524-.524V20.56h4.501v27.846h.035z"
            ></path>
            <path
                fill="#000"
                d="M41.052 39.786H26.048a.537.537 0 00-.524.523v4.048c0 .28.245.523.524.523h14.97c.279 0 .523-.244.523-.523v-4.048c.035-.279-.21-.523-.489-.523zm-.523 4.048H26.57v-3.001H40.53v3zM41.052 32.807H26.048a.537.537 0 00-.524.523v4.048c0 .28.245.524.524.524h14.97c.279 0 .523-.245.523-.524v-4.047c.035-.28-.21-.524-.489-.524zm-.523 4.048H26.57v-3.001H40.53v3zM25.524 26.352v4.047c0 .28.245.524.524.524h14.97c.279 0 .523-.244.523-.524v-4.047a.537.537 0 00-.524-.524h-14.97a.537.537 0 00-.523.524zm1.047.523H40.53v3.001H26.57v-3z"
            ></path>
        </svg>
    );
}

export default QuickAndSimple;
